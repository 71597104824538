.prosprr {
  background-color: #ffffff;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
}
body{
  overflow-x: hidden;
}

.prosprr .div {
  background-color: #ffffff;
  height: 22592px;
  /* overflow: hidden; */
  position: relative;
  width: 1920px;
}

.prosprr .overlap {
  height: 1130px;
  left: 0;
  position: absolute;
  top: 0;
  width: 1920px;
}

.prosprr .rectangle {
  background-color: #ecfff8;
  height: 740px;
  left: 0;
  position: absolute;
  top: 0;
  width: 1920px;
}

.prosprr .the-challenge {
  align-items: center;
  background-color: #007662;
  display: flex;
  flex-direction: column;
  gap: 24px;
  justify-content: center;
  left: 0;
  padding: 80px 120px;
  position: absolute;
  top: 657px;
  width: 1920px;
}

.prosprr .frame {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 48px;
  position: relative;
  width: 100%;
}

.prosprr .frame-2 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 32px;
  justify-content: center;
  position: relative;
  width: 100%;
}

.prosprr .text-wrapper {
  align-self: stretch;
  color: #ffffff;
  font-family: "Neue Haas Grotesk Text Pro-65Medium", Helvetica;
  font-size: 40px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 56px;
  margin-top: -1px;
  position: relative;
}

.prosprr .frame-3 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  justify-content: space-between;
  position: relative;
  width: 100%;
}

.prosprr .p {
  color: #ffffff;
  font-family: "Neue Haas Grotesk Text Pro-55Roman", Helvetica;
  font-size: 32px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 44.8px;
  margin-top: -1px;
  position: relative;
  width: 657px;
}

.prosprr .text-wrapper-2 {
  color: #ffffff;
  font-family: "Neue Haas Grotesk Text Pro-55Roman", Helvetica;
  font-size: 32px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 44.8px;
  margin-top: -1px;
  position: relative;
  width: 631px;
}

.prosprr .frame-4 {
  align-items: flex-start;
  background: linear-gradient(180deg, rgb(201, 249, 223) 0%, rgba(255, 255, 255, 0) 100%);
  display: flex;
  flex-direction: column;
  gap: 64px;
  justify-content: center;
  left: 0;
  padding: 120px;
  position: absolute;
  top: 0;
  width: 1920px;
}

.prosprr .heading-wrapper {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  gap: 260px;
  position: relative;
  width: 100%;
}

.prosprr .heading {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 32px;
  position: relative;
  width: 1181px;
}

.prosprr .text-wrapper-3 {
  color: #000000;
  font-family: "Neue Haas Grotesk Text Pro-55Roman", Helvetica;
  font-size: 96px;
  font-weight: 400;
  letter-spacing: -0.48px;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  width: fit-content;
}

.prosprr .website-digital {
  color: #737373;
  font-family: "Neue Haas Grotesk Text Pro-55Roman", Helvetica;
  font-size: 32px;
  font-weight: 400;
  letter-spacing: -0.16px;
  line-height: 48px;
  position: relative;
  width: 432px;
}

.prosprr .group {
  height: 108px;
  position: relative;
  width: 341px;
}

.prosprr .frame-5 {
  align-items: flex-start;
  display: inline-flex;
  gap: 16px;
  left: 0;
  position: absolute;
  top: 52px;
}

.prosprr .UIX {
  height: 56px;
  position: relative;
  width: 165px;
}

.prosprr .frame-wrapper {
  align-items: flex-start;
  background-color: #a7a7a71f;
  border-radius: 28px;
  display: inline-flex;
  flex-direction: column;
  gap: 10px;
  justify-content: center;
  padding: 4px 16px 4px 4px;
  position: relative;
}

.prosprr .frame-6 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 12px;
  position: relative;
}

.prosprr .overlap-group-wrapper {
  background-color: #ffffff;
  border-radius: 24px;
  height: 48px;
  position: relative;
  width: 48px;
}

.prosprr .overlap-group {
  height: 22px;
  left: 13px;
  position: relative;
  top: 13px;
  width: 22px;
}

.prosprr .line {
  height: 22px;
  left: 9px;
  position: absolute;
  top: 0;
  width: 5px;
}

.prosprr .img {
  height: 4px;
  left: 0;
  position: absolute;
  top: 9px;
  width: 22px;
}

.prosprr .text-wrapper-4 {
  color: #000000;
  font-family: "Neue Haas Grotesk Display Pro-75Bold", Helvetica;
  font-size: 24px;
  font-weight: 700;
  letter-spacing: 0.46px;
  line-height: 32.9px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.prosprr .div-wrapper {
  height: 56px;
  position: relative;
  width: 158px;
}

.prosprr .text-wrapper-5 {
  color: #000000;
  font-family: "Neue Haas Grotesk Text Pro-65Medium", Helvetica;
  font-size: 24px;
  font-weight: 500;
  height: 40px;
  left: 0;
  letter-spacing: -0.39px;
  line-height: 40px;
  position: absolute;
  top: 0;
  white-space: nowrap;
}

.prosprr .frame-7 {
  background-color: #e9ecf0;
  height: 7795px;
  left: 0;
  position: absolute;
  top: 1130px;
  width: 1921px;
}

.prosprr .text-wrapper-6 {
  color: #000000;
  font-family: "Neue Haas Grotesk Text Pro-55Roman", Helvetica;
  font-size: 20px;
  font-weight: 400;
  height: 28px;
  left: 241px;
  letter-spacing: 0;
  line-height: 28px;
  position: absolute;
  top: 67px;
  white-space: nowrap;
}

.prosprr .home-page {
  height: 7700px;
  left: 156px;
  position: absolute;
  top: 95px;
  width: 1656px;
}

.prosprr .element {
  height: 1180px;
  left: 0;
  position: absolute;
  top: 9515px;
  width: 1920px;
}

.prosprr .overlap-2 {
  height: 37px;
  left: 120px;
  position: absolute;
  top: -86px;
  width: 222px;
}

.prosprr .text-wrapper-7 {
  color: #2f2f2f;
  font-family: "Neue Haas Grotesk Display Pro-55Roman", Helvetica;
  font-size: 32px;
  font-weight: 400;
  left: 0;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 0;
  white-space: nowrap;
}

.prosprr .frame-8 {
  height: 1180px;
  left: 1024px;
  overflow: hidden;
  position: absolute;
  top: 0;
  width: 896px;
}

.prosprr .overlap-group-2 {
  background-color: #101728;
  border-radius: 2px;
  height: 1180px;
  position: relative;
  width: 1020px;
}

.prosprr .overlap-3 {
  height: 769px;
  left: 156px;
  position: absolute;
  top: 89px;
  width: 719px;
}

.prosprr .text-wrapper-8 {
  color: #ffffff;
  font-family: "Lexend Deca", Helvetica;
  font-size: 549px;
  font-weight: 400;
  left: 0;
  letter-spacing: -2.75px;
  line-height: 768.6px;
  position: absolute;
  top: 0;
  white-space: nowrap;
}

.prosprr .text-wrapper-9 {
  color: #ffffff;
  font-family: "Lexend Deca", Helvetica;
  font-size: 32px;
  font-weight: 400;
  left: 9px;
  letter-spacing: -0.16px;
  line-height: 44.8px;
  position: absolute;
  top: 39px;
  white-space: nowrap;
}

.prosprr .text-wrapper-10 {
  color: #ffffff;
  font-family: "Lexend Deca", Helvetica;
  font-size: 48px;
  font-weight: 300;
  left: 156px;
  letter-spacing: 0;
  line-height: 67.2px;
  position: absolute;
  top: 858px;
  width: 693px;
}

.prosprr .frame-9 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  height: 1180px;
  left: 0;
  position: absolute;
  top: 0;
  width: 960px;
}

.prosprr .frame-10 {
  align-items: flex-start;
  display: inline-flex;
  height: 639px;
  position: relative;
}

.prosprr .frame-11 {
  align-items: flex-start;
  align-self: stretch;
  background-color: #78cc7a;
  display: flex;
  flex-direction: column;
  gap: 24px;
  justify-content: flex-end;
  padding: 80px 64px;
  position: relative;
  width: 640px;
}

.prosprr .frame-12 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 12px;
  justify-content: flex-end;
  position: relative;
}

.prosprr .text-wrapper-11 {
  color: var(--core-colorswhite);
  font-family: "Neue Haas Grotesk Text Pro-65Medium", Helvetica;
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  width: fit-content;
}

.prosprr .frame-13 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 32px;
  position: relative;
}

.prosprr .text-wrapper-12 {
  color: var(--core-colorswhite);
  font-family: "Neue Haas Grotesk Text Pro-55Roman", Helvetica;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  width: fit-content;
}

.prosprr .frame-14 {
  align-items: flex-start;
  align-self: stretch;
  background-color: #007662;
  display: flex;
  flex-direction: column;
  gap: 32px;
  justify-content: flex-end;
  padding: 80px 64px;
  position: relative;
  width: 320px;
}

.prosprr .frame-15 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 12px;
  position: relative;
}

.prosprr .frame-16 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  height: 341px;
  position: relative;
  width: 100%;
}

.prosprr .frame-17 {
  align-items: flex-start;
  align-self: stretch;
  background-color: #c9f9df;
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-grow: 1;
  gap: 32px;
  justify-content: flex-end;
  padding: 64px;
  position: relative;
}

.prosprr .text-wrapper-13 {
  color: #000000;
  font-family: "Neue Haas Grotesk Text Pro-65Medium", Helvetica;
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  width: fit-content;
}

.prosprr .text-wrapper-14 {
  color: #000000;
  font-family: "Neue Haas Grotesk Text Pro-55Roman", Helvetica;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  width: fit-content;
}

.prosprr .frame-18 {
  align-items: flex-start;
  align-self: stretch;
  background-color: #e9ecf0;
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-grow: 1;
  gap: 32px;
  justify-content: flex-end;
  padding: 64px;
  position: relative;
}

.prosprr .frame-19 {
  align-items: flex-start;
  align-self: stretch;
  background-color: #dde9e7;
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-grow: 1;
  gap: 32px;
  justify-content: flex-end;
  padding: 64px;
  position: relative;
}

.prosprr .frame-20 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  position: relative;
  width: 100%;
}

.prosprr .frame-21 {
  align-items: flex-start;
  background-color: #485058;
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-grow: 1;
  gap: 32px;
  height: 200px;
  justify-content: flex-end;
  padding: 56px 64px;
  position: relative;
}

.prosprr .text-wrapper-15 {
  color: #ffffff;
  font-family: "Neue Haas Grotesk Text Pro-65Medium", Helvetica;
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  width: fit-content;
}

.prosprr .text-wrapper-16 {
  color: #ffffff;
  font-family: "Neue Haas Grotesk Text Pro-55Roman", Helvetica;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  width: fit-content;
}

.prosprr .frame-22 {
  align-items: flex-start;
  background-color: #101728;
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-grow: 1;
  gap: 32px;
  height: 200px;
  justify-content: flex-end;
  padding: 56px 64px;
  position: relative;
}

.prosprr .frame-23 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 32px;
  left: 0;
  padding: 120px 160px;
  position: absolute;
  top: 8925px;
  width: 1920px;
}

.prosprr .text-wrapper-17 {
  color: #2f2f2f;
  font-family: "Neue Haas Grotesk Text Pro-75Bold", Helvetica;
  font-size: 24px;
  font-weight: 700;
  letter-spacing: -0.3px;
  line-height: 38.4px;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.prosprr .text-wrapper-18 {
  color: #676768;
  font-family: "Neue Haas Grotesk Text Pro-55Roman", Helvetica;
  font-size: 40px;
  font-weight: 400;
  letter-spacing: -0.3px;
  line-height: 56px;
  margin-right: -36px;
  position: relative;
  width: 1636px;
}

.prosprr .mask-group-wrapper {
  background-image: url(../../../static/img/rectangle-122.svg);
  background-size: 100% 100%;
  height: 540px;
  left: 0;
  position: absolute;
  top: 21184px;
  width: 1920px;
}

.prosprr .mask-group {
  height: 540px;
  left: 0;
  position: absolute;
  top: 0;
  width: 1920px;
}

.prosprr .why-crypto-SIP {
  height: 4015px;
  left: 824px;
  position: absolute;
  top: 10791px;
  width: 976px;
}

.prosprr .why-prosppr {
  height: 4677px;
  left: 0;
  position: absolute;
  top: 10791px;
  width: 759px;
}

.prosprr .learning-blog {
  background-color: #ffffff;
  border-radius: 12px;
  height: 3541px;
  left: 818px;
  overflow: hidden;
  position: absolute;
  top: 14890px;
  width: 982px;
}

.prosprr .footers {
  align-items: flex-start;
  background-color: var(--neutral-05);
  display: flex;
  flex-direction: column;
  gap: 6.82px;
  left: 0;
  padding: 32.73px 0px 16.37px;
  position: absolute;
  top: 3320px;
  width: 982px;
}

.prosprr .frame-24 {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 27.28px;
  position: relative;
  width: 100%;
}

.prosprr .frame-25 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  justify-content: space-between;
  padding: 0px 65.47px;
  position: relative;
  width: 100%;
}

.prosprr .frame-26 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 32.73px;
  position: relative;
}

.prosprr .img-2 {
  flex: 0 0 auto;
  position: relative;
}

.prosprr .frame-27 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 8.18px;
  position: relative;
}

.prosprr .text-wrapper-19 {
  color: var(--neutral-00);
  font-family: "Livvic", Helvetica;
  font-size: 12.3px;
  font-weight: 600;
  letter-spacing: -0.12px;
  line-height: normal;
  margin-top: -0.68px;
  position: relative;
  width: fit-content;
}

.prosprr .frame-28 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 8.18px;
  position: relative;
}

.prosprr .image {
  height: 30.69px;
  position: relative;
  width: 90.7px;
}

.prosprr .frame-29 {
  align-items: flex-end;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 21.82px;
  position: relative;
}

.prosprr .frame-30 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 21.82px;
  position: relative;
}

.prosprr .company-links {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 6.82px;
  position: relative;
}

.prosprr .text-wrapper-20 {
  color: var(--neutral-00);
  font-family: "Plus Jakarta Sans", Helvetica;
  font-size: 10.9px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -0.68px;
  position: relative;
  width: fit-content;
}

.prosprr .div-2 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 16.37px;
  position: relative;
}

.prosprr .frame-31 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 10.91px;
  position: relative;
}

.prosprr .divider {
  height: 1px;
  margin-top: -0.68px;
  position: relative;
  width: 982px;
}

.prosprr .text-wrapper-21 {
  color: var(--neutral-01);
  font-family: "Lexend Deca", Helvetica;
  font-size: 9.5px;
  font-weight: 400;
  letter-spacing: -0.12px;
  line-height: 13.4px;
  margin-top: -0.68px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.prosprr .frame-32 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 16.37px;
  position: relative;
}

.prosprr .text-wrapper-22 {
  color: var(--neutral-01);
  font-family: "Lexend Deca", Helvetica;
  font-size: 9.5px;
  font-weight: 600;
  letter-spacing: -0.12px;
  line-height: 13.4px;
  margin-top: -0.68px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.prosprr .ellipse {
  background-color: var(--neutral-01);
  border-radius: 1.36px;
  height: 2.73px;
  position: relative;
  width: 2.73px;
}

.prosprr .overlap-4 {
  height: 1206px;
  left: 0;
  position: absolute;
  top: 0;
  width: 982px;
}

.prosprr .header {
  align-items: center;
  display: flex;
  gap: 10.91px;
  left: 0;
  padding: 13.64px 65.47px;
  position: absolute;
  top: 0;
  width: 982px;
}

.prosprr .frame-33 {
  flex: 0 0 auto;
  height: 22.07px;
  position: relative;
}

.prosprr .frame-34 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 295.96px;
  height: 34.78px;
  padding: 0px 0px 0px 4.77px;
  position: relative;
}

.prosprr .div-3 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 16.37px;
  position: relative;
}

.prosprr .text-wrapper-23 {
  color: var(--neutral-04);
  font-family: "Lexend Deca", Helvetica;
  font-size: 10.9px;
  font-weight: 400;
  letter-spacing: -0.13px;
  line-height: 15.3px;
  margin-top: -0.68px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.prosprr .buttons {
  align-items: center;
  background-color: var(--neutral-04);
  border-radius: 2.35px;
  display: flex;
  gap: 5.87px;
  justify-content: center;
  padding: 9.38px;
  position: relative;
  width: 113.2px;
}

.prosprr .text-wrapper-24 {
  color: #ffffff;
  font-family: "Lexend Deca", Helvetica;
  font-size: 11px;
  font-weight: 500;
  letter-spacing: -0.13px;
  line-height: 15.4px;
  margin-left: -0.28px;
  margin-right: -0.28px;
  margin-top: -0.59px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.prosprr .header-2 {
  background-color: #ecfff8;
  height: 1206px;
  left: 0;
  overflow: hidden;
  position: absolute;
  top: 0;
  width: 982px;
}

.prosprr .overlap-group-3 {
  height: 1563px;
  left: -372px;
  position: relative;
  top: -399px;
  width: 1577px;
}

.prosprr .ellipse-2 {
  background: radial-gradient(
    50% 50% at 50% 50%,
    rgba(97.15, 224.19, 147.96, 0.42) 0%,
    rgba(193.25, 218.42, 203.32, 0) 60.32%,
    rgba(217, 217, 217, 0) 80.73%
  );
  border-radius: 323.92px;
  filter: blur(4.11px);
  height: 648px;
  left: 129px;
  position: absolute;
  top: 634px;
  transform: rotate(36.59deg);
  width: 648px;
}

.prosprr .ellipse-3 {
  background: radial-gradient(50% 50% at 50% 50%, rgba(106.57, 188.06, 174.35, 0.38) 0%, rgba(217, 217, 217, 0) 100%);
  border-radius: 345.75px;
  height: 691px;
  left: 886px;
  position: absolute;
  top: 0;
  width: 691px;
}

.prosprr .ellipse-4 {
  background: radial-gradient(50% 50% at 50% 50%, rgba(246.5, 231.5, 96.55, 0.27) 0%, rgba(217, 217, 217, 0) 100%);
  border-radius: 268px;
  height: 536px;
  left: 955px;
  position: absolute;
  top: 805px;
  width: 536px;
}

.prosprr .ellipse-5 {
  background: radial-gradient(50% 50% at 50% 50%, rgba(96.55, 246.5, 120.54, 0.22) 0%, rgba(217, 217, 217, 0) 87.86%);
  border-radius: 341.31px/349.84px;
  height: 700px;
  left: 152px;
  position: absolute;
  top: 98px;
  width: 683px;
}

.prosprr .frame-35 {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 10.91px;
  left: 440px;
  position: absolute;
  top: 516px;
  width: 848px;
}

.prosprr .text-wrapper-25 {
  align-self: stretch;
  color: var(--neutral-04);
  font-family: "Lexend Deca", Helvetica;
  font-size: 32.7px;
  font-weight: 600;
  letter-spacing: -0.4px;
  line-height: 39.3px;
  margin-top: -0.68px;
  position: relative;
  text-align: center;
}

.prosprr .text-wrapper-26 {
  color: var(--neutral-03);
  font-family: "Lexend Deca", Helvetica;
  font-size: 13.6px;
  font-weight: 400;
  letter-spacing: -0.17px;
  line-height: 19.1px;
  position: relative;
  text-align: center;
  width: 461.68px;
}

.prosprr .popular {
  align-items: flex-start;
  background-color: #ffffff;
  border-radius: 10.91px;
  box-shadow: 2.05px 4.77px 8.18px #0e56490f;
  display: inline-flex;
  flex-direction: column;
  gap: 16.37px;
  height: 404px;
  left: 972px;
  padding: 21.82px 16.37px;
  position: absolute;
  top: 649px;
}

.prosprr .frame-36 {
  align-items: flex-start;
  align-self: stretch;
  border-bottom-style: solid;
  border-bottom-width: 1.5px;
  border-color: var(--primary-05);
  display: flex;
  flex: 0 0 auto;
  padding: 10.91px;
  position: relative;
  width: 100%;
}

.prosprr .text-wrapper-27 {
  color: var(--neutral-05);
  font-family: "Lexend Deca", Helvetica;
  font-size: 20.5px;
  font-weight: 600;
  letter-spacing: -0.25px;
  line-height: 24.6px;
  margin-top: -1.5px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.prosprr .frame-37 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 10.91px;
  position: relative;
}

.prosprr .frame-38 {
  align-items: flex-start;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 5.46px;
  padding: 5.46px 10.91px;
  position: relative;
  width: 283.69px;
}

.prosprr .text-wrapper-28 {
  align-self: stretch;
  color: var(--primary-02);
  font-family: "Lexend Deca", Helvetica;
  font-size: 9.5px;
  font-weight: 400;
  letter-spacing: 0.31px;
  line-height: 13.4px;
  margin-top: -1.64px;
  position: relative;
}

.prosprr .text-wrapper-29 {
  align-self: stretch;
  color: var(--neutral-05);
  font-family: "Lexend Deca", Helvetica;
  font-size: 13.6px;
  font-weight: 500;
  letter-spacing: -0.17px;
  line-height: 16.4px;
  position: relative;
}

.prosprr .text-wrapper-30 {
  align-self: stretch;
  color: var(--primary-02);
  font-family: "Lexend Deca", Helvetica;
  font-size: 9.5px;
  font-weight: 400;
  letter-spacing: 0.31px;
  line-height: 13.4px;
  margin-top: -1.5px;
  position: relative;
}

.prosprr .text-wrapper-31 {
  align-self: stretch;
  color: var(--primary-02);
  font-family: "Lexend Deca", Helvetica;
  font-size: 9.5px;
  font-weight: 400;
  letter-spacing: 0.31px;
  line-height: 13.4px;
  margin-top: -1.36px;
  position: relative;
}

.prosprr .how-compounding {
  align-items: center;
  background-color: #ffffff;
  border-radius: 10.91px;
  box-shadow: 2.05px 4.77px 8.18px #0e56490f;
  display: flex;
  flex-direction: column;
  gap: 10.91px;
  height: 404px;
  left: 437px;
  position: absolute;
  top: 649px;
  width: 518px;
}

.prosprr .frame-39 {
  align-items: flex-start;
  align-self: stretch;
  border-radius: 8.18px;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  overflow: hidden;
  position: relative;
  width: 100%;
}

.prosprr .image-2 {
  align-self: stretch;
  height: 251.64px;
  object-fit: cover;
  position: relative;
  width: 100%;
}

.prosprr .frame-40 {
  align-items: flex-start;
  align-self: stretch;
  border-radius: 8.18px;
  display: flex;
  flex-direction: column;
  gap: 5.46px;
  height: 152.07px;
  padding: 16.37px;
  position: relative;
  width: 100%;
}

.prosprr .frame-41 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 5.46px;
  position: relative;
}

.prosprr .text-wrapper-32 {
  color: var(--primary-02);
  font-family: "Lexend Deca", Helvetica;
  font-size: 9.5px;
  font-weight: 400;
  letter-spacing: 0.31px;
  line-height: 13.4px;
  margin-top: -0.68px;
  position: relative;
  width: 471.91px;
}

.prosprr .frame-42 {
  align-items: flex-start;
  display: inline-flex;
  flex-direction: column;
  gap: 5.46px;
  height: 80.47px;
  position: relative;
}

.prosprr .text-wrapper-33 {
  color: var(--neutral-04);
  font-family: "Lexend Deca", Helvetica;
  font-size: 20.5px;
  font-weight: 500;
  letter-spacing: -0.25px;
  line-height: 24.6px;
  margin-top: -0.68px;
  position: relative;
  width: 471.91px;
}

.prosprr .frame-43 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  position: relative;
}

.prosprr .text-wrapper-34 {
  color: var(--neutral-01);
  font-family: "Lexend Deca", Helvetica;
  font-size: 9.5px;
  font-weight: 400;
  letter-spacing: -0.12px;
  line-height: 13.4px;
  margin-top: -0.68px;
  position: relative;
  width: 471.91px;
}

.prosprr .text-wrapper-35 {
  color: var(--primary-02);
  font-family: "Lexend Deca", Helvetica;
  font-size: 10.9px;
  font-weight: 500;
  letter-spacing: -0.13px;
  line-height: 15.3px;
  position: relative;
  width: 471.91px;
}

.prosprr .frame-44 {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  gap: 5.46px;
  position: relative;
  width: 100%;
}

.prosprr .text-wrapper-36 {
  color: var(--neutral-04);
  font-family: "Lexend Deca", Helvetica;
  font-size: 8.2px;
  font-weight: 400;
  letter-spacing: -0.06px;
  line-height: 11.5px;
  margin-top: -0.68px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.prosprr .ellipse-6 {
  background-color: var(--neutral-05);
  border-radius: 1.36px;
  height: 2.73px;
  position: relative;
  width: 2.73px;
}

.prosprr .text-wrapper-37 {
  color: var(--neutral-02);
  font-family: "Lexend Deca", Helvetica;
  font-size: 8.2px;
  font-weight: 400;
  letter-spacing: -0.06px;
  line-height: 11.5px;
  margin-top: -0.68px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.prosprr .header-3 {
  align-items: center;
  display: flex;
  gap: 10.91px;
  left: 372px;
  padding: 13.64px 65.47px;
  position: absolute;
  top: 399px;
  width: 982px;
}

.prosprr .downlaod-prosppr {
  position: relative;
  width: 113.2px;
}

.prosprr .frame-45 {
  align-items: flex-start;
  display: inline-flex;
  flex-direction: column;
  gap: 16.37px;
  left: 439px;
  position: absolute;
  top: 1086px;
}

.prosprr .frame-46 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 21.82px;
  position: relative;
}

.prosprr .frame-47 {
  align-items: flex-start;
  border-bottom-style: solid;
  border-bottom-width: 1.5px;
  border-color: #00766287;
  display: flex;
  flex: 0 0 auto;
  padding: 16.37px 10.91px;
  position: relative;
  width: 846.97px;
}

.prosprr .frame-48 {
  align-items: center;
  background-color: var(--primary-02);
  border-radius: 4.77px;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 6.82px;
  justify-content: center;
  padding: 5.46px 10.91px;
  position: relative;
}

.prosprr .text-wrapper-38 {
  color: var(--accent-01);
  font-family: "Lexend Deca", Helvetica;
  font-size: 9.5px;
  font-weight: 500;
  letter-spacing: -0.12px;
  line-height: 13.4px;
  margin-top: -0.68px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.prosprr .frame-49 {
  align-items: center;
  background-color: #007d681f;
  border-radius: 4.77px;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 6.82px;
  justify-content: center;
  padding: 5.46px 10.91px;
  position: relative;
}

.prosprr .text-wrapper-39 {
  color: var(--primary-02);
  font-family: "Lexend Deca", Helvetica;
  font-size: 9.5px;
  font-weight: 500;
  letter-spacing: -0.12px;
  line-height: 13.4px;
  margin-top: -0.68px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.prosprr .frame-50 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 26.6px;
  position: relative;
}

.prosprr .frame-51 {
  align-items: flex-start;
  background-color: #ffffff;
  border-radius: 7.36px;
  box-shadow: 0.61px 4.29px 14.72px #00000012;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  position: relative;
  width: 264.59px;
}

.prosprr .frame-52 {
  align-self: stretch;
  background-image: url(../../../static/img/image-38-3.png);
  background-position: 50% 50%;
  background-size: cover;
  height: 174.77px;
  position: relative;
  width: 100%;
}

.prosprr .frame-53 {
  align-items: flex-start;
  align-self: stretch;
  border-radius: 7.36px;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 32.73px;
  padding: 16.37px 16.37px 10.91px;
  position: relative;
  width: 100%;
}

.prosprr .frame-54 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 5.46px;
  position: relative;
  width: 100%;
}

.prosprr .text-wrapper-40 {
  align-self: stretch;
  color: var(--primary-02);
  font-family: "Lexend Deca", Helvetica;
  font-size: 9.5px;
  font-weight: 400;
  letter-spacing: 0.31px;
  line-height: 13.4px;
  margin-top: -0.61px;
  position: relative;
}

.prosprr .frame-55 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 10.91px;
  position: relative;
  width: 100%;
}

.prosprr .text-wrapper-41 {
  align-self: stretch;
  color: var(--neutral-04);
  font-family: "Lexend Deca", Helvetica;
  font-size: 13.6px;
  font-weight: 500;
  letter-spacing: -0.17px;
  line-height: 16.4px;
  margin-top: -0.61px;
  position: relative;
}

.prosprr .text-wrapper-42 {
  align-self: stretch;
  color: var(--neutral-01);
  font-family: "Lexend Deca", Helvetica;
  font-size: 8.2px;
  font-weight: 400;
  letter-spacing: -0.1px;
  line-height: 11.5px;
  margin-top: -0.61px;
  position: relative;
}

.prosprr .read-more {
  align-self: stretch;
  flex: 0 0 auto;
  position: relative;
  width: 100%;
}

.prosprr .frame-56 {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  gap: 4.91px;
  position: relative;
  width: 100%;
}

.prosprr .text-wrapper-43 {
  color: var(--neutral-04);
  font-family: "Lexend Deca", Helvetica;
  font-size: 7.4px;
  font-weight: 400;
  letter-spacing: -0.05px;
  line-height: 10.3px;
  margin-top: -0.61px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.prosprr .ellipse-7 {
  background-color: var(--neutral-05);
  border-radius: 1.23px;
  height: 2.45px;
  position: relative;
  width: 2.45px;
}

.prosprr .text-wrapper-44 {
  color: var(--neutral-02);
  font-family: "Lexend Deca", Helvetica;
  font-size: 7.4px;
  font-weight: 400;
  letter-spacing: -0.05px;
  line-height: 10.3px;
  margin-top: -0.61px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.prosprr .frame-57 {
  align-self: stretch;
  background-image: url(../../../static/img/image-39-2.png);
  background-position: 50% 50%;
  background-size: cover;
  height: 174.77px;
  position: relative;
  width: 100%;
}

.prosprr .frame-58 {
  align-self: stretch;
  background-image: url(../../../static/img/image-40-29.png);
  background-position: 50% 50%;
  background-size: cover;
  height: 174.77px;
  position: relative;
  width: 100%;
}

.prosprr .frame-59 {
  align-items: flex-start;
  background-color: #ffffff;
  border-bottom-style: solid;
  border-bottom-width: 3.41px;
  border-color: var(--primary-02);
  border-radius: 16.37px 16.37px 0px 0px;
  box-shadow: 0px 4.77px 10.91px #c3e4cc;
  display: flex;
  flex-direction: column;
  gap: 21.82px;
  left: 63px;
  padding: 16.37px 32.73px 27.28px;
  position: absolute;
  top: 1242px;
  width: 855px;
}

.prosprr .frame-60 {
  align-items: center;
  display: flex;
  flex: 0 0 auto;
  gap: 5.46px;
  justify-content: center;
  position: relative;
  width: 173.21px;
}

.prosprr .text-wrapper-45 {
  color: var(--neutral-05);
  font-family: "Lexend Deca", Helvetica;
  font-size: 20.5px;
  font-weight: 600;
  letter-spacing: -0.25px;
  line-height: 24.6px;
  margin-left: -2.3px;
  margin-top: -0.68px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.prosprr .rectangle-2 {
  height: 16.37px;
  margin-right: -2.3px;
  position: relative;
  width: 16.37px;
}

.prosprr .frame-61 {
  align-items: flex-start;
  background-color: #ffffff;
  box-shadow: 0.61px 4.29px 14.72px #00000012;
  display: flex;
  flex-direction: column;
  position: relative;
  width: 362.79px;
}

.prosprr .frame-62 {
  align-self: stretch;
  background-image: url(../../../static/img/image-40-28.png);
  background-position: 50% 50%;
  background-size: cover;
  height: 190.94px;
  position: relative;
  width: 100%;
}

.prosprr .frame-63 {
  align-items: flex-start;
  align-self: stretch;
  border-radius: 7.36px;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 10.91px;
  padding: 16.37px 10.91px;
  position: relative;
  width: 100%;
}

.prosprr .frame-64 {
  align-items: flex-start;
  background-color: #ffffff;
  border-bottom-style: solid;
  border-bottom-width: 1.5px;
  border-color: var(--primary-05);
  border-top-style: solid;
  border-top-width: 1.5px;
  display: flex;
  flex: 0 0 auto;
  gap: 16.37px;
  padding: 21.82px 10.91px;
  position: relative;
  width: 398.94px;
}

.prosprr .frame-65 {
  background-image: url(../../../static/img/image-40-27.png);
  background-position: 50% 50%;
  background-size: cover;
  height: 76.38px;
  position: relative;
  width: 81.15px;
}

.prosprr .frame-66 {
  align-items: flex-start;
  border-radius: 7.36px;
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-grow: 1;
  gap: 16.37px;
  height: 59.33px;
  position: relative;
}

.prosprr .frame-67 {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  gap: 4.91px;
  margin-bottom: -15.04px;
  position: relative;
  width: 100%;
}

.prosprr .frame-68 {
  align-items: flex-start;
  background-color: #ffffff;
  border-bottom-style: solid;
  border-bottom-width: 1.5px;
  border-color: var(--primary-05);
  display: flex;
  flex: 0 0 auto;
  gap: 16.37px;
  padding: 21.82px 10.91px;
  position: relative;
  width: 398.94px;
}

.prosprr .frame-69 {
  background-image: url(../../../static/img/image-40-26.png);
  background-position: 50% 50%;
  background-size: cover;
  height: 76.38px;
  position: relative;
  width: 81.15px;
}

.prosprr .more-blogs {
  flex: 0 0 auto;
  margin-bottom: -0.25px;
  position: relative;
}

.prosprr .frame-70 {
  align-items: flex-start;
  background-color: #ffffff;
  border-bottom-style: solid;
  border-bottom-width: 3.41px;
  border-color: #408fd7;
  border-radius: 16.37px 16.37px 0px 0px;
  box-shadow: 0px 4.77px 10.91px #c3e4cc;
  display: flex;
  flex-direction: column;
  gap: 21.82px;
  left: 63px;
  padding: 16.37px 32.73px 27.28px;
  position: absolute;
  top: 1637px;
  width: 413px;
}

.prosprr .frame-71 {
  align-items: center;
  display: flex;
  flex: 0 0 auto;
  gap: 5.46px;
  position: relative;
  width: 173.21px;
}

.prosprr .text-wrapper-46 {
  color: var(--neutral-05);
  font-family: "Lexend Deca", Helvetica;
  font-size: 20.5px;
  font-weight: 600;
  letter-spacing: -0.25px;
  line-height: 24.6px;
  margin-top: -0.68px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.prosprr .rectangle-3 {
  height: 16.37px;
  position: relative;
  width: 16.37px;
}

.prosprr .frame-72 {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 21.82px;
  position: relative;
  width: 100%;
}

.prosprr .frame-73 {
  align-items: flex-start;
  align-self: stretch;
  background-color: #ffffff;
  box-shadow: 0.61px 4.29px 14.72px #00000012;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  overflow: hidden;
  position: relative;
  width: 100%;
}

.prosprr .frame-74 {
  align-self: stretch;
  background-image: url(../../../static/img/image-40-25.png);
  background-position: 50% 50%;
  background-size: cover;
  height: 190.94px;
  position: relative;
  width: 100%;
}

.prosprr .frame-75 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 16.37px;
  position: relative;
  width: 100%;
}

.prosprr .frame-76 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  position: relative;
  width: 100%;
}

.prosprr .frame-77 {
  align-items: flex-start;
  align-self: stretch;
  background-color: #ffffff;
  border-bottom-style: solid;
  border-bottom-width: 1.5px;
  border-color: var(--primary-05);
  border-top-style: solid;
  border-top-width: 1.5px;
  display: flex;
  flex: 0 0 auto;
  gap: 16.37px;
  padding: 21.82px 10.91px;
  position: relative;
  width: 100%;
}

.prosprr .frame-78 {
  background-image: url(../../../static/img/image-40-24.png);
  background-position: 50% 50%;
  background-size: cover;
  height: 76.38px;
  position: relative;
  width: 81.15px;
}

.prosprr .frame-79 {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  gap: 5.46px;
  margin-bottom: -15.04px;
  position: relative;
  width: 100%;
}

.prosprr .frame-80 {
  align-items: flex-start;
  align-self: stretch;
  background-color: #ffffff;
  border-bottom-style: solid;
  border-bottom-width: 1.5px;
  border-color: var(--primary-05);
  display: flex;
  flex: 0 0 auto;
  gap: 16.37px;
  padding: 21.82px 10.91px;
  position: relative;
  width: 100%;
}

.prosprr .frame-81 {
  background-image: url(../../../static/img/image-40-23.png);
  background-position: 50% 50%;
  background-size: cover;
  height: 76.38px;
  position: relative;
  width: 81.15px;
}

.prosprr .frame-82 {
  align-items: flex-start;
  background-color: #ffffff;
  border-bottom-style: solid;
  border-bottom-width: 3.41px;
  border-color: #2a56c5;
  border-radius: 16.37px 16.37px 0px 0px;
  box-shadow: 0px 4.77px 10.91px #c3e4cc;
  display: flex;
  flex-direction: column;
  gap: 21.82px;
  height: 606px;
  left: 63px;
  padding: 16.37px 32.73px 27.28px;
  position: absolute;
  top: 2326px;
  width: 502px;
}

.prosprr .image-wrapper {
  background-image: url(../../../static/img/image-40-22.png);
  background-position: 50% 50%;
  background-size: cover;
  height: 76.38px;
  overflow: hidden;
  position: relative;
  width: 81.15px;
}

.prosprr .image-3 {
  height: 76px;
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 0;
  width: 81px;
}

.prosprr .frame-83 {
  background-image: url(../../../static/img/image-40-21.png);
  background-position: 50% 50%;
  background-size: cover;
  height: 76.38px;
  position: relative;
  width: 81.15px;
}

.prosprr .frame-84 {
  background-image: url(../../../static/img/image-40-20.png);
  background-position: 50% 50%;
  background-size: cover;
  height: 76.38px;
  position: relative;
  width: 81.15px;
}

.prosprr .frame-85 {
  background-image: url(../../../static/img/image-40-19.png);
  background-position: 50% 50%;
  background-size: cover;
  height: 76.38px;
  position: relative;
  width: 81.15px;
}

.prosprr .frame-86 {
  align-items: flex-start;
  background-color: #ffffff;
  border-bottom-style: solid;
  border-bottom-width: 3.41px;
  border-color: #fc8a49;
  border-radius: 16.37px 16.37px 0px 0px;
  box-shadow: 0px 4.77px 10.91px #c3e4cc;
  display: flex;
  flex-direction: column;
  gap: 21.82px;
  left: 505px;
  padding: 16.37px 32.73px 27.28px;
  position: absolute;
  top: 1637px;
  width: 413px;
}

.prosprr .frame-87 {
  align-self: stretch;
  background-image: url(../../../static/img/image-40-18.png);
  background-position: 50% 50%;
  background-size: cover;
  height: 190.94px;
  position: relative;
  width: 100%;
}

.prosprr .frame-88 {
  background-image: url(../../../static/img/image-40-17.png);
  background-position: 50% 50%;
  background-size: cover;
  height: 76.38px;
  position: relative;
  width: 81.15px;
}

.prosprr .frame-89 {
  background-image: url(../../../static/img/image-40-16.png);
  background-position: 50% 50%;
  background-size: cover;
  height: 76.38px;
  position: relative;
  width: 81.15px;
}

.prosprr .frame-90 {
  align-items: flex-start;
  background-color: #ffffff;
  border-bottom-style: solid;
  border-bottom-width: 3.41px;
  border-color: #d14e4e;
  border-radius: 16.37px 16.37px 0px 0px;
  box-shadow: 0px 4.77px 10.91px #c3e4cc;
  display: flex;
  flex-direction: column;
  gap: 21.82px;
  height: 606px;
  left: 594px;
  padding: 16.37px 32.73px 27.28px;
  position: absolute;
  top: 2326px;
  width: 321px;
}

.prosprr .frame-91 {
  align-self: stretch;
  background-image: url(../../../static/img/image-40-15.png);
  background-position: 50% 50%;
  background-size: cover;
  height: 130.93px;
  position: relative;
  width: 100%;
}

.prosprr .frame-92 {
  background-image: url(../../../static/img/image-40-14.png);
  background-position: 50% 50%;
  background-size: cover;
  height: 76.38px;
  position: relative;
  width: 81.15px;
}

.prosprr .text-wrapper-47 {
  color: var(--neutral-02);
  font-family: "Lexend Deca", Helvetica;
  font-size: 7.4px;
  font-weight: 400;
  letter-spacing: -0.05px;
  line-height: 10.3px;
  margin-right: -9.07px;
  margin-top: -0.61px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.prosprr .frame-93 {
  background-image: url(../../../static/img/image-40-13.png);
  background-position: 50% 50%;
  background-size: cover;
  height: 76.38px;
  position: relative;
  width: 81.15px;
}

.prosprr .overlap-wrapper {
  height: 3877px;
  left: 0;
  overflow: hidden;
  position: absolute;
  top: 15566px;
  width: 759px;
}

.prosprr .overlap-5 {
  height: 5063px;
  left: -33px;
  position: relative;
  width: 792px;
}

.prosprr .rectangle-4 {
  background-color: #263f39;
  height: 3877px;
  left: 0;
  position: absolute;
  top: 0;
  width: 792px;
}

.prosprr .why-crypto-SIP-2 {
  background-color: #ffffff;
  height: 4948px;
  left: 161px;
  overflow: hidden;
  position: absolute;
  top: 115px;
  width: 470px;
}

.prosprr .overlap-6 {
  background-image: url(../../../static/img/hero-section-bg-3.png);
  background-size: 100% 100%;
  height: 588px;
  left: 0;
  position: absolute;
  top: 0;
  width: 470px;
}

.prosprr .overlap-7 {
  height: 488px;
  left: 0;
  position: absolute;
  top: 99px;
  width: 470px;
}

.prosprr .component {
  height: 488px;
  left: 0;
  overflow: hidden;
  position: absolute;
  top: 0;
  width: 470px;
}

.prosprr .overlap-group-4 {
  height: 517px;
  left: -188px;
  position: relative;
  top: 15px;
  width: 638px;
}

.prosprr .ellipse-8 {
  background: radial-gradient(50% 50% at 50% 50%, rgba(97.15, 224.19, 147.96, 0.42) 0%, rgba(217, 217, 217, 0) 88.55%);
  border-radius: 219.07px;
  filter: blur(2.78px);
  height: 438px;
  left: 0;
  position: absolute;
  top: 79px;
  width: 438px;
}

.prosprr .frame-94 {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 20.89px;
  justify-content: center;
  left: 209px;
  padding: 0px 15.67px;
  position: absolute;
  top: 0;
  width: 430px;
}

.prosprr .frame-95 {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  justify-content: center;
  position: relative;
  width: 100%;
}

.prosprr .frame-96 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 10.44px;
  position: relative;
}

.prosprr .text-wrapper-48 {
  align-self: stretch;
  color: var(--primary-01);
  font-family: "Lexend Deca", Helvetica;
  font-size: 15.7px;
  font-weight: 500;
  letter-spacing: -0.19px;
  line-height: 18.8px;
  margin-top: -1.31px;
  position: relative;
  text-align: center;
}

.prosprr .most-preferred {
  color: var(--neutral-04);
  font-family: "Lexend Deca", Helvetica;
  font-size: 36.6px;
  font-weight: 500;
  letter-spacing: -1.1px;
  line-height: 41.8px;
  position: relative;
  text-align: center;
  width: 349.89px;
}

.prosprr .text-wrapper-49 {
  align-self: stretch;
  color: var(--neutral-01);
  font-family: "Lexend Deca", Helvetica;
  font-size: 15.7px;
  font-weight: 400;
  letter-spacing: -0.19px;
  line-height: 21.9px;
  position: relative;
  text-align: center;
}

.prosprr .frame-97 {
  height: 214px;
  left: 0;
  position: absolute;
  top: 274px;
  width: 470px;
}

.prosprr .overlap-8 {
  height: 360px;
  left: -169px;
  position: relative;
  top: -60px;
  width: 789px;
}

.prosprr .ellipse-9 {
  background: radial-gradient(
    50% 50% at 50% 50%,
    rgba(97.15, 224.19, 147.96, 0.42) 1.63%,
    rgba(217, 217, 217, 0) 53.12%
  );
  border-radius: 180.17px;
  filter: blur(2.29px);
  height: 360px;
  left: 0;
  position: absolute;
  top: 0;
  width: 360px;
}

.prosprr .frame-98 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  height: 23px;
  left: 144px;
  opacity: 0.77;
  position: absolute;
  top: 162px;
  width: 22px;
}

.prosprr .headline {
  align-self: stretch;
  color: var(--neutral-01);
  flex: 1;
  font-family: "Lexend Deca", Helvetica;
  font-size: 4.8px;
  font-weight: 500;
  letter-spacing: -0.06px;
  line-height: 5.8px;
  margin-top: -0.24px;
  position: relative;
}

.prosprr .headline-2 {
  align-self: stretch;
  color: var(--primary-03);
  flex: 1;
  font-family: "Lexend Deca", Helvetica;
  font-size: 7.2px;
  font-weight: 600;
  letter-spacing: -0.09px;
  line-height: 8.7px;
  margin-top: -5.78px;
  position: relative;
}

.prosprr .ellipse-10 {
  background: radial-gradient(50% 50% at 50% 50%, rgba(246.5, 231.5, 96.55, 0.19) 0%, rgba(217, 217, 217, 0) 100%);
  border-radius: 149.06px;
  height: 298px;
  left: 491px;
  position: absolute;
  top: 3px;
  width: 298px;
}

.prosprr .frame-99 {
  align-items: flex-start;
  display: inline-flex;
  flex-direction: column;
  gap: 2.61px;
  left: 234px;
  opacity: 0.77;
  position: absolute;
  top: 181px;
}

.prosprr .headline-3 {
  color: var(--neutral-01);
  font-family: "Lexend Deca", Helvetica;
  font-size: 7.8px;
  font-weight: 500;
  letter-spacing: -0.1px;
  line-height: 9.4px;
  margin-top: -0.24px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.prosprr .headline-4 {
  color: var(--primary-03);
  font-family: "Lexend Deca", Helvetica;
  font-size: 10.4px;
  font-weight: 600;
  letter-spacing: -0.13px;
  line-height: 12.5px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.prosprr .group-2 {
  height: 49px;
  left: 222px;
  position: absolute;
  top: 187px;
  width: 18px;
}

.prosprr .overlap-group-5 {
  height: 49px;
  position: relative;
}

.prosprr .div-4 {
  background: radial-gradient(
    50% 50% at 50% 50%,
    rgba(46.11, 238, 14.88, 0.67) 0%,
    rgba(42.1, 255, 7.44, 0.21) 14.56%,
    rgba(41.02, 215.69, 12.58, 0) 32.05%
  );
  border-radius: 8.91px;
  height: 18px;
  left: 0;
  position: absolute;
  top: 31px;
  width: 18px;
}

.prosprr .vector {
  height: 40px;
  left: 8px;
  position: absolute;
  top: 2px;
  width: 1px;
}

.prosprr .ellipse-11 {
  background-color: var(--primary-03);
  border-radius: 1.14px;
  height: 2px;
  left: 8px;
  position: absolute;
  top: 0;
  width: 2px;
}

.prosprr .ellipse-12 {
  background-color: var(--primary-03);
  border-radius: 1.14px;
  height: 2px;
  left: 8px;
  position: absolute;
  top: 41px;
  width: 2px;
}

.prosprr .group-3 {
  height: 47px;
  left: 514px;
  position: absolute;
  top: 141px;
  width: 18px;
}

.prosprr .overlap-9 {
  height: 47px;
  position: relative;
}

.prosprr .ellipse-13 {
  background: radial-gradient(
    50% 50% at 50% 50%,
    rgba(46.11, 238, 14.88, 0.67) 0%,
    rgba(42.1, 255, 7.44, 0.21) 14.56%,
    rgba(41.02, 215.69, 12.58, 0) 32.05%
  );
  border-radius: 8.91px/8.7px;
  height: 17px;
  left: 0;
  position: absolute;
  top: 30px;
  width: 18px;
}

.prosprr .vector-2 {
  height: 39px;
  left: 8px;
  position: absolute;
  top: 2px;
  width: 1px;
}

.prosprr .ellipse-14 {
  background-color: var(--primary-03);
  border-radius: 1.14px/1.11px;
  height: 2px;
  left: 8px;
  position: absolute;
  top: 0;
  width: 2px;
}

.prosprr .ellipse-15 {
  background-color: var(--primary-03);
  border-radius: 1.14px/1.11px;
  height: 2px;
  left: 8px;
  position: absolute;
  top: 40px;
  width: 2px;
}

.prosprr .group-4 {
  height: 47px;
  left: 605px;
  position: absolute;
  top: 134px;
  width: 18px;
}

.prosprr .group-5 {
  height: 49px;
  left: 131px;
  position: absolute;
  top: 168px;
  width: 18px;
}

.prosprr .ellipse-16 {
  background-color: var(--primary-03);
  border-radius: 1.14px;
  height: 2px;
  left: 8px;
  position: relative;
  top: 10px;
  width: 2px;
}

.prosprr .vector-3 {
  height: 39px;
  left: 326px;
  position: absolute;
  top: -5682px;
  width: 1px;
}

.prosprr .group-6 {
  height: 49px;
  left: 312px;
  position: absolute;
  top: 121px;
  width: 18px;
}

.prosprr .group-7 {
  height: 33px;
  left: 424px;
  position: absolute;
  top: 119px;
  width: 18px;
}

.prosprr .overlap-10 {
  height: 33px;
  position: relative;
}

.prosprr .ellipse-17 {
  background: radial-gradient(
    50% 50% at 50% 50%,
    rgba(46.11, 238, 14.88, 0.67) 0%,
    rgba(42.1, 255, 7.44, 0.21) 14.56%,
    rgba(41.02, 215.69, 12.58, 0) 32.05%
  );
  border-radius: 8.91px;
  height: 18px;
  left: 0;
  position: absolute;
  top: 15px;
  width: 18px;
}

.prosprr .vector-4 {
  height: 25px;
  left: 8px;
  position: absolute;
  top: 1px;
  width: 1px;
}

.prosprr .ellipse-18 {
  background-color: var(--primary-03);
  border-radius: 1.14px;
  height: 2px;
  left: 8px;
  position: absolute;
  top: 25px;
  width: 2px;
}

.prosprr .vector-5 {
  height: 127px;
  left: 169px;
  position: absolute;
  top: 127px;
  width: 470px;
}

.prosprr .frame-100 {
  align-items: flex-start;
  display: inline-flex;
  flex-direction: column;
  gap: 2.61px;
  left: 326px;
  opacity: 0.77;
  position: absolute;
  top: 114px;
}

.prosprr .frame-101 {
  align-items: flex-start;
  display: inline-flex;
  flex-direction: column;
  gap: 2.61px;
  left: 440px;
  opacity: 0.77;
  position: absolute;
  top: 114px;
}

.prosprr .frame-102 {
  align-items: flex-start;
  display: inline-flex;
  flex-direction: column;
  gap: 2.61px;
  left: 527px;
  opacity: 0.77;
  position: absolute;
  top: 136px;
}

.prosprr .frame-103 {
  align-items: flex-start;
  display: inline-flex;
  flex-direction: column;
  gap: 2.61px;
  left: 617px;
  opacity: 0.77;
  position: absolute;
  top: 129px;
}

.prosprr .frame-104 {
  align-items: center;
  display: inline-flex;
  gap: 125.33px;
  left: 20px;
  position: absolute;
  top: 12px;
}

.prosprr .frame-105 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 15.67px;
  position: relative;
}

.prosprr .leading-icon-l-use {
  height: 31.33px !important;
  width: 31.33px !important;
}

.prosprr .navigation-menu-px {
  height: 16px !important;
  left: 4px !important;
  top: 8px !important;
  width: 24px !important;
}

.prosprr .vector-6 {
  height: 28.72px;
  position: relative;
  width: 100.25px;
}

.prosprr .frame-106 {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 31.33px;
  left: 0;
  overflow: hidden;
  position: absolute;
  top: 3316px;
  width: 473px;
}

.prosprr .frame-107 {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 10.44px;
  justify-content: center;
  position: relative;
  width: 100%;
}

.prosprr .headline-5 {
  color: var(--primary-03);
  font-family: "Plus Jakarta Sans", Helvetica;
  font-size: 15.7px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -0.53px;
  position: relative;
  text-align: center;
  width: fit-content;
}

.prosprr .headline-6 {
  color: #1a1a1a;
  font-family: "Lexend Deca", Helvetica;
  font-size: 26.1px;
  font-weight: 600;
  letter-spacing: -0.32px;
  line-height: 31.3px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

.prosprr .frame-108 {
  align-items: center;
  display: flex;
  flex: 0 0 auto;
  gap: 31.33px;
  justify-content: center;
  padding: 6.33px 50.64px;
  position: relative;
  width: 468.69px;
}

.prosprr .how-compounding-2 {
  align-items: center;
  background-color: #f8f9fa;
  border: 0.53px solid;
  border-color: var(--accent-02);
  border-radius: 4.22px;
  box-shadow: -2.64px 1.58px 6.33px #0e56491f;
  display: flex;
  flex-direction: column;
  gap: 8.44px;
  margin-bottom: -0.53px;
  margin-left: -300.53px;
  margin-top: -0.53px;
  opacity: 0.6;
  position: relative;
  width: 302.64px;
}

.prosprr .frame-109 {
  align-items: flex-start;
  align-self: stretch;
  border-radius: 6.33px;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  overflow: hidden;
  position: relative;
  width: 100%;
}

.prosprr .image-4 {
  align-self: stretch;
  height: 159.28px;
  object-fit: cover;
  position: relative;
  width: 100%;
}

.prosprr .frame-110 {
  align-items: flex-start;
  align-self: stretch;
  border-radius: 6.33px;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 8.44px;
  justify-content: center;
  padding: 12.66px 12.66px 25.32px;
  position: relative;
  width: 100%;
}

.prosprr .text-wrapper-50 {
  align-self: stretch;
  color: var(--neutral-04);
  font-family: "Lexend Deca", Helvetica;
  font-size: 20.9px;
  font-weight: 500;
  letter-spacing: -0.25px;
  line-height: 25.1px;
  margin-top: -0.53px;
  position: relative;
}

.prosprr .frame-111 {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 16.88px;
  justify-content: center;
  position: relative;
  width: 100%;
}

.prosprr .text-wrapper-51 {
  align-self: stretch;
  color: var(--neutral-03);
  font-family: "Lexend Deca", Helvetica;
  font-size: 15.7px;
  font-weight: 300;
  height: 52.22px;
  letter-spacing: -0.19px;
  line-height: 21.9px;
  margin-top: -0.53px;
  position: relative;
}

.prosprr .read-more-2 {
  flex: 0 0 auto;
  margin-right: -465.16px;
  margin-top: -8887.49px;
  position: relative;
}

.prosprr .how-compounding-3 {
  align-items: center;
  background-color: #f8f9fa;
  border: 0.53px solid;
  border-color: var(--accent-02);
  border-radius: 4.22px;
  box-shadow: -2.64px 1.58px 6.33px #0e56491f;
  display: flex;
  flex-direction: column;
  gap: 8.44px;
  margin-bottom: -0.53px;
  margin-top: -0.53px;
  position: relative;
  width: 302.64px;
}

.prosprr .how-compounding-4 {
  align-items: center;
  background-color: #f8f9fa;
  border: 0.53px solid;
  border-color: var(--accent-02);
  border-radius: 4.22px;
  box-shadow: -2.64px 1.58px 6.33px #0e56491f;
  display: flex;
  flex-direction: column;
  gap: 8.44px;
  margin-bottom: -0.53px;
  margin-right: -300.53px;
  margin-top: -0.53px;
  opacity: 0.6;
  position: relative;
  width: 302.64px;
}

.prosprr .read-more-3 {
  flex: 0 0 auto;
  margin-left: -27.51px;
  margin-top: -8887.49px;
  position: relative;
}

.prosprr .frame-112 {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 20.89px;
  justify-content: center;
  left: 21px;
  position: absolute;
  top: 3823px;
  width: 428px;
}

.prosprr .text-wrapper-52 {
  color: #000000;
  font-family: "Lexend Deca", Helvetica;
  font-size: 26.1px;
  font-weight: 600;
  letter-spacing: -0.32px;
  line-height: 31.3px;
  margin-top: -1.31px;
  position: relative;
  text-align: center;
  width: 389.06px;
}

.prosprr .text-wrapper-53 {
  color: var(--neutral-01);
  font-family: "Lexend Deca", Helvetica;
  font-size: 15.7px;
  font-weight: 400;
  letter-spacing: -0.19px;
  line-height: 21.9px;
  position: relative;
  text-align: center;
  width: 377.31px;
}

.prosprr .frame-113 {
  height: 405px;
  left: 261px;
  position: absolute;
  top: 3965px;
  width: 251px;
}

.prosprr .leaf {
  height: 96px;
  left: 307px;
  object-fit: cover;
  position: absolute;
  top: -8967px;
  width: 139px;
}

.prosprr .element-2 {
  height: 385px;
  left: 56px;
  position: absolute;
  top: -9276px;
  width: 180px;
}

.prosprr .frame-114 {
  align-items: center;
  background-color: #101728;
  display: inline-flex;
  flex-direction: column;
  gap: 20.89px;
  left: 0;
  padding: 31.33px 20.89px;
  position: absolute;
  top: 4411px;
}

.prosprr .frame-115 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 20.89px;
  position: relative;
}

.prosprr .frame-116 {
  align-items: flex-start;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 31.33px;
  position: relative;
  width: 428.22px;
}

.prosprr .prosppr-logo-wrapper {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 11.8px;
  position: relative;
}

.prosprr .prosppr-logo {
  height: 41.78px;
  margin-right: -296.11px;
  margin-top: -9753.81px;
  position: relative;
  width: 135.78px;
}

.prosprr .frame-117 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 15.67px;
  position: relative;
  width: 100%;
}

.prosprr .text-wrapper-54 {
  color: var(--accent-01);
  font-family: "Lexend Deca", Helvetica;
  font-size: 20.9px;
  font-weight: 600;
  letter-spacing: -0.21px;
  line-height: normal;
  margin-top: -1.31px;
  position: relative;
  width: fit-content;
}

.prosprr .frame-118 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  gap: 15.67px;
  position: relative;
  width: 100%;
}

.prosprr .image-5 {
  height: 57.44px;
  margin-right: -40.22px;
  margin-top: -9868.58px;
  position: relative;
  width: 172.33px;
}

.prosprr .image-6 {
  height: 57.44px;
  margin-right: -228.22px;
  margin-top: -9868.58px;
  position: relative;
  width: 172.33px;
}

.prosprr .frame-119 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 39.17px;
  position: relative;
}

.prosprr .text-wrapper-55 {
  color: var(--accent-01);
  font-family: "Lexend Deca", Helvetica;
  font-size: 18.3px;
  font-weight: 400;
  letter-spacing: -0.18px;
  line-height: normal;
  margin-top: -1.31px;
  position: relative;
  width: fit-content;
}

.prosprr .text-wrapper-56 {
  color: var(--accent-01);
  font-family: "Lexend Deca", Helvetica;
  font-size: 18.3px;
  font-weight: 400;
  letter-spacing: -0.18px;
  line-height: normal;
  position: relative;
  width: fit-content;
}

.prosprr .divider-2 {
  height: 1px;
  margin-right: -296.11px;
  margin-top: -9429.03px;
  position: relative;
  width: 428.22px;
}

.prosprr .frame-120 {
  align-items: center;
  display: flex;
  flex: 0 0 auto;
  justify-content: space-between;
  position: relative;
  width: 428.22px;
}

.prosprr .text-wrapper-57 {
  color: var(--neutral-00);
  font-family: "Figtree", Helvetica;
  font-size: 18.3px;
  font-weight: 700;
  letter-spacing: -0.18px;
  line-height: normal;
  position: relative;
  width: fit-content;
}

.prosprr .social-buttons {
  flex: 0 0 auto;
  margin-right: -296.11px;
  margin-top: -10099.47px;
  position: relative;
}

.prosprr .divider-3 {
  height: 1px;
  margin-right: -296.11px;
  margin-top: -9355.92px;
  position: relative;
  width: 428.22px;
}

.prosprr .frame-121 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 19.58px;
  position: relative;
}

.prosprr .text-wrapper-58 {
  color: var(--neutral-01);
  font-family: "Figtree", Helvetica;
  font-size: 13.1px;
  font-weight: 400;
  letter-spacing: -0.13px;
  line-height: normal;
  margin-top: -1.31px;
  position: relative;
  width: fit-content;
}

.prosprr .frame-122 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 15.67px;
  position: relative;
}

.prosprr .text-wrapper-59 {
  color: var(--neutral-01);
  font-family: "Figtree", Helvetica;
  font-size: 15.7px;
  font-weight: 400;
  letter-spacing: -0.16px;
  line-height: normal;
  margin-top: -1.31px;
  position: relative;
  width: fit-content;
}

.prosprr .thumbs-up {
  -webkit-backdrop-filter: blur(19.58px) brightness(100%);
  backdrop-filter: blur(19.58px) brightness(100%);
  background-color: #f2f6f18f;
  border: 5.33px solid;
  border-color: transparent;
  border-image: linear-gradient(
      to bottom,
      rgba(44.41, 140.25, 94.53, 0) 14.38%,
      rgba(66.07, 158.51, 102.4, 0.29) 45.13%,
      rgb(120, 204, 122) 100%
    )
    1;
  border-radius: 38.99px;
  box-shadow: 2.61px 4.57px 12.19px #0e564938;
  height: 146px;
  left: 683px;
  overflow: hidden;
  position: absolute;
  top: 1837px;
  width: 146px;
}

.prosprr .image-7 {
  height: 93px;
  left: -339px;
  object-fit: cover;
  position: absolute;
  top: -7121px;
  width: 93px;
}

.prosprr .frame-123 {
  align-items: center;
  display: inline-flex;
  flex-direction: column;
  gap: 41.78px;
  left: 0;
  position: absolute;
  top: 629px;
}

.prosprr .frame-124 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 41.78px;
  position: relative;
}

.prosprr .frame-125 {
  align-items: center;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 41.78px;
  position: relative;
  width: 428.22px;
}

.prosprr .frame-126 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 20.89px;
  padding: 0px 20.89px;
  position: relative;
}

.prosprr .headline-7 {
  color: var(--neutral-04);
  font-family: "Lexend Deca", Helvetica;
  font-size: 26.1px;
  font-weight: 600;
  letter-spacing: -0.32px;
  line-height: 31.3px;
  margin-top: -1.31px;
  position: relative;
  text-align: center;
  width: 381.22px;
}

.prosprr .group-8 {
  height: 330.83px;
  position: relative;
  width: 359.26px;
}

.prosprr .overlap-11 {
  height: 331px;
  position: relative;
  width: 359px;
}

.prosprr .ellipse-19 {
  background: linear-gradient(180deg, rgb(0, 118, 98) 0%, rgb(120, 204, 122) 100%);
  border-radius: 155.56px;
  height: 311px;
  left: 0;
  position: absolute;
  top: 0;
  width: 311px;
}

.prosprr .leaf-2 {
  height: 102px;
  left: 220px;
  position: absolute;
  top: 229px;
  width: 139px;
}

.prosprr .istock {
  height: 293px;
  left: 36px;
  position: absolute;
  top: 25px;
  width: 274px;
}

.prosprr .emoji {
  background-color: #fcfffb8f;
  border: 2.15px solid;
  border-color: transparent;
  border-image: linear-gradient(
      to bottom,
      rgba(44.41, 140.25, 94.53, 0) 1.65%,
      rgba(66.07, 158.51, 102.4, 0.29) 33.26%,
      rgb(120, 204, 122) 100%
    )
    1;
  border-radius: 15.75px;
  box-shadow: -0.53px 1.58px 4.92px 1.05px #0e564938;
  height: 59px;
  left: 36px;
  overflow: hidden;
  position: absolute;
  top: 67px;
  width: 59px;
}

.prosprr .getpaidstock {
  height: 50px;
  left: 4px;
  object-fit: cover;
  position: absolute;
  top: 6px;
  width: 50px;
}

.prosprr .cryptobasket {
  -webkit-backdrop-filter: blur(10.55px) brightness(100%);
  backdrop-filter: blur(10.55px) brightness(100%);
  background-color: #f7fef88f;
  border: 1.32px solid;
  border-color: transparent;
  border-image: linear-gradient(to bottom, rgb(247.56, 255, 253.76), rgb(120, 204, 122)) 1;
  border-radius: 7.34px;
  box-shadow: -0.47px 3.26px 3.67px #0e564938;
  height: 105px;
  left: 216px;
  position: absolute;
  top: 9px;
  width: 110px;
}

.prosprr .overlap-group-6 {
  height: 16px;
  left: 0;
  position: absolute;
  top: 87px;
  width: 107px;
}

.prosprr .rectangle-5 {
  background-color: #ffffff;
  border-radius: 0px 7.34px 0px 7.34px;
  height: 16px;
  left: 42px;
  position: absolute;
  top: 0;
  transform: rotate(180deg);
  width: 66px;
}

.prosprr .text-wrapper-60 {
  color: var(--primary-03);
  font-family: "Lexend Deca", Helvetica;
  font-size: 7.8px;
  font-weight: 400;
  left: 68px;
  letter-spacing: -0.1px;
  line-height: 11px;
  position: absolute;
  top: 3px;
  white-space: nowrap;
}

.prosprr .rectangle-6 {
  background-color: var(--primary-02);
  border-radius: 0px 7.34px 7.34px 7.34px;
  height: 16px;
  left: 0;
  position: absolute;
  top: 0;
  width: 53px;
}

.prosprr .text-wrapper-61 {
  color: var(--neutral-00);
  font-family: "Lexend Deca", Helvetica;
  font-size: 7.8px;
  font-weight: 400;
  left: 18px;
  letter-spacing: -0.1px;
  line-height: 11px;
  position: absolute;
  top: 3px;
  white-space: nowrap;
}

.prosprr .text-wrapper-62 {
  color: var(--neutral-05);
  font-family: "Lexend", Helvetica;
  font-size: 7.8px;
  font-weight: 400;
  left: 5px;
  letter-spacing: 0.04px;
  line-height: normal;
  position: absolute;
  top: 13px;
}

.prosprr .text-wrapper-63 {
  color: var(--neutral-05);
  font-family: "Lexend", Helvetica;
  font-size: 7.8px;
  font-weight: 400;
  left: 5px;
  letter-spacing: 0.04px;
  line-height: normal;
  position: absolute;
  top: 47px;
}

.prosprr .frame-127 {
  align-items: flex-start;
  background-color: var(--neutral-00);
  border: 0.92px solid;
  border-color: var(--primary-01);
  border-radius: 3.67px;
  display: flex;
  gap: 4.59px;
  height: 17px;
  left: 5px;
  padding: 3.67px 4.59px;
  position: absolute;
  top: 58px;
  width: 98px;
}

.prosprr .text-wrapper-64 {
  color: var(--neutral-01);
  font-family: "Lexend Deca", Helvetica;
  font-size: 7.8px;
  font-weight: 400;
  letter-spacing: -0.1px;
  line-height: 11px;
  margin-top: -0.92px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.prosprr .frame-128 {
  align-items: flex-start;
  background-color: var(--neutral-00);
  border: 0.92px solid;
  border-color: var(--primary-01);
  border-radius: 3.67px;
  display: flex;
  gap: 4.59px;
  height: 17px;
  left: 5px;
  padding: 3.67px 4.59px;
  position: absolute;
  top: 25px;
  width: 98px;
}

.prosprr .group-9 {
  height: 371.38px;
  position: relative;
  width: 353.22px;
}

.prosprr .overlap-12 {
  height: 371px;
  position: relative;
  width: 353px;
}

.prosprr .image-8 {
  background-image: url(../../../static/img/image-12.png);
  background-size: 100% 100%;
  height: 335px;
  left: 0;
  position: absolute;
  top: 37px;
  width: 353px;
}

.prosprr .ETH {
  -webkit-backdrop-filter: blur(7.93px) brightness(100%);
  backdrop-filter: blur(7.93px) brightness(100%);
  background-color: #f7fef8ad;
  border: 1.68px solid;
  border-color: transparent;
  border-image: linear-gradient(to bottom, rgb(247.56, 255, 253.76), rgb(120, 204, 122)) 1;
  border-radius: 7.76px;
  box-shadow: -1.68px 5.61px 8.98px #0e56491f;
  height: 129px;
  left: 22px;
  overflow: hidden;
  position: absolute;
  top: 0;
  width: 108px;
}

.prosprr .overlap-group-7 {
  height: 126px;
  position: relative;
  top: 3px;
}

.prosprr .group-10 {
  height: 53px;
  left: 0;
  position: absolute;
  top: 73px;
  width: 108px;
}

.prosprr .frame-129 {
  align-items: flex-start;
  display: inline-flex;
  flex-direction: column;
  gap: 15.26px;
  left: 0;
  padding: 6.79px;
  position: absolute;
  top: 0;
}

.prosprr .frame-130 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 42.8px;
  position: relative;
}

.prosprr .ethereum-logo-wrapper {
  align-items: center;
  background-color: #fffdfe;
  border-radius: 5.44px;
  box-shadow: 0px 1.41px 4.22px #00000026;
  display: flex;
  gap: 3.4px;
  height: 20.46px;
  justify-content: center;
  padding: 3.4px;
  position: relative;
  width: 21.19px;
}

.prosprr .ethereum-logo {
  height: 17.17px;
  margin-bottom: -1.75px;
  margin-top: -1.76px;
  position: relative;
  width: 12.79px;
}

.prosprr .rise {
  align-items: center;
  background-color: #00000033;
  border-radius: 2.04px;
  display: flex;
  gap: 1.88px;
  justify-content: center;
  padding: 1.02px 1.5px;
  position: relative;
  width: 30.12px;
}

.prosprr .group-11 {
  height: 4.09px;
  position: relative;
  width: 4.6px;
}

.prosprr .text-wrapper-65 {
  color: var(--accent-01);
  font-family: "Lexend Deca", Helvetica;
  font-size: 7.2px;
  font-weight: 300;
  letter-spacing: 0.04px;
  line-height: normal;
  margin-top: -0.51px;
  position: relative;
  width: 14.31px;
}

.prosprr .text-wrapper-66 {
  color: var(--primary-02);
  font-family: "Lexend Deca", Helvetica;
  font-size: 4.2px;
  font-weight: 400;
  letter-spacing: -0.05px;
  line-height: 5.9px;
  margin-top: -0.42px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.prosprr .text-wrapper-67 {
  color: var(--primary-02);
  font-family: "Lexend Deca", Helvetica;
  font-size: 15.3px;
  font-weight: 300;
  letter-spacing: -0.19px;
  line-height: 21.4px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.prosprr .group-12 {
  background-image: url(../../../static/img/image-9.png);
  background-size: 100% 100%;
  height: 331.82px;
  position: relative;
  width: 352.06px;
}

.prosprr .check {
  height: 65px;
  left: 266px;
  position: absolute;
  top: 104px;
  width: 65px;
}

.prosprr .frame-131 {
  -webkit-backdrop-filter: blur(7.86px) brightness(100%);
  align-items: flex-start;
  backdrop-filter: blur(7.86px) brightness(100%);
  background-color: #f7fef8b8;
  border: 1.51px solid;
  border-color: transparent;
  border-image: linear-gradient(to bottom, rgb(247.56, 255, 253.76), rgb(120, 204, 122)) 1;
  border-radius: 8.61px;
  box-shadow: -0.54px 1.08px 19.37px #4545451f;
  display: flex;
  flex-direction: column;
  gap: 4.31px;
  height: 67px;
  left: 4px;
  padding: 8.61px;
  position: absolute;
  top: 14px;
  width: 138px;
}

.prosprr .frame-132 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 8.61px;
  position: relative;
}

.prosprr .frame-133 {
  align-items: center;
  display: flex;
  flex: 0 0 auto;
  gap: 34.44px;
  position: relative;
  width: 121.06px;
}

.prosprr .group-13 {
  height: 14.08px;
  position: relative;
  width: 47.88px;
}

.prosprr .overlap-group-8 {
  height: 14px;
  position: relative;
  width: 48px;
}

.prosprr .vector-wrapper {
  background-color: #f3b82f;
  border-radius: 3.54px;
  height: 14px;
  left: 0;
  position: absolute;
  top: 0;
  width: 14px;
}

.prosprr .vector-7 {
  height: 9px;
  left: 3px;
  position: absolute;
  top: 3px;
  width: 9px;
}

.prosprr .img-wrapper {
  background-color: #000001;
  border-radius: 3.54px;
  height: 14px;
  left: 11px;
  position: absolute;
  top: 0;
  width: 14px;
}

.prosprr .vector-8 {
  height: 8px;
  left: 2px;
  position: absolute;
  top: 3px;
  width: 9px;
}

.prosprr .group-wrapper {
  height: 14px;
  left: 23px;
  position: absolute;
  top: 0;
  width: 14px;
}

.prosprr .PNG-wrapper {
  background-color: #162852;
  border-radius: 3.54px;
  height: 14px;
  position: relative;
}

.prosprr .PNG {
  height: 14px;
  left: 1px;
  object-fit: cover;
  position: absolute;
  top: 0;
  width: 13px;
}

.prosprr .group-14 {
  background-color: #e60077;
  border-radius: 3.54px;
  height: 14px;
  left: 34px;
  position: absolute;
  top: 0;
  width: 14px;
}

.prosprr .vector-9 {
  height: 9px;
  left: 3px;
  position: absolute;
  top: 2px;
  width: 8px;
}

.prosprr .frame-134 {
  align-items: center;
  display: flex;
  flex: 1;
  flex-grow: 1;
  justify-content: space-between;
  position: relative;
}

.prosprr .CTA {
  align-items: center;
  background-color: #388573;
  border-radius: 1.41px;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 2.15px;
  height: 12.92px;
  justify-content: center;
  padding: 2.82px;
  position: relative;
}

.prosprr .text-wrapper-68 {
  color: var(--neutral-00);
  font-family: "Lexend Deca", Helvetica;
  font-size: 4.2px;
  font-weight: 700;
  letter-spacing: -0.05px;
  line-height: 5.9px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

.prosprr .frame-135 {
  height: 8.45px;
  position: relative;
  width: 8.45px;
}

.prosprr .text-wrapper-69 {
  color: var(--neutral-05);
  font-family: "Lexend", Helvetica;
  font-size: 6.2px;
  font-weight: 400;
  letter-spacing: 0.03px;
  line-height: normal;
  position: relative;
  width: fit-content;
}

.prosprr .frame-136 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 12.92px;
  margin-bottom: -0.57px;
  position: relative;
}

.prosprr .frame-137 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 2.15px;
  justify-content: center;
  position: relative;
}

.prosprr .text-wrapper-70 {
  color: var(--neutral-03);
  font-family: "Lexend Deca", Helvetica;
  font-size: 4.5px;
  font-weight: 400;
  letter-spacing: 0.02px;
  line-height: normal;
  margin-top: -0.54px;
  position: relative;
  width: fit-content;
}

.prosprr .text-wrapper-71 {
  color: #263f39;
  font-family: "Lexend Deca", Helvetica;
  font-size: 5.4px;
  font-weight: 500;
  letter-spacing: 0.03px;
  line-height: normal;
  position: relative;
  width: fit-content;
}

.prosprr .rise-2 {
  align-items: center;
  background-color: #00000033;
  border-radius: 1.17px;
  display: flex;
  flex: 0 0 auto;
  gap: 1.08px;
  justify-content: center;
  padding: 0.58px 0.86px;
  position: relative;
  width: 16.08px;
}

.prosprr .group-15 {
  height: 2.34px;
  position: relative;
  width: 2.63px;
}

.prosprr .text-wrapper-72 {
  color: var(--accent-01);
  font-family: "Lexend Deca", Helvetica;
  font-size: 4.1px;
  font-weight: 300;
  letter-spacing: 0.02px;
  line-height: normal;
  margin-top: -0.29px;
  position: relative;
  width: 8.19px;
}

.prosprr .frame-138 {
  height: 669.75px;
  position: relative;
  width: 470px;
}

.prosprr .overlap-13 {
  height: 670px;
  position: relative;
}

.prosprr .frame-139 {
  background-image: url(../../../static/img/rectangle-94-2.png);
  background-size: 100% 100%;
  height: 670px;
  left: 0;
  position: absolute;
  top: 0;
  width: 470px;
}

.prosprr .frame-140 {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 10.44px;
  left: 21px;
  position: relative;
  top: 48px;
  width: 428px;
}

.prosprr .frame-141 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 20.89px;
  position: relative;
}

.prosprr .headline-8 {
  color: var(--neutral-00);
  font-family: "Lexend Deca", Helvetica;
  font-size: 26.1px;
  font-weight: 600;
  letter-spacing: -0.32px;
  line-height: 31.3px;
  margin-top: -1.31px;
  position: relative;
  text-align: center;
  width: 399.5px;
}

.prosprr .headline-9 {
  color: var(--accent-01);
  font-family: "Lexend Deca", Helvetica;
  font-size: 15.7px;
  font-weight: 400;
  letter-spacing: -0.19px;
  line-height: 21.9px;
  position: relative;
  text-align: center;
  width: 383.83px;
}

.prosprr .investment-on {
  height: 492px;
  left: 0;
  position: absolute;
  top: 178px;
  width: 470px;
}

.prosprr .frame-142 {
  align-items: center;
  display: inline-flex;
  flex-direction: column;
  gap: 41.78px;
  left: 54px;
  position: absolute;
  top: 4013px;
}

.prosprr .frame-143 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 9.37px;
  justify-content: center;
  position: relative;
}

.prosprr .android-store {
  align-self: stretch;
  height: 39.57px;
  margin-right: -242.22px;
  margin-top: -9324.28px;
  position: relative;
  width: 100%;
}

.prosprr .apple-store {
  height: 41.25px;
  margin-right: -242.22px;
  margin-top: -9275.34px;
  position: relative;
  width: 128.8px;
}

.prosprr .frame-144 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 15.67px;
  justify-content: center;
  position: relative;
}

.prosprr .image-9 {
  height: 115.49px;
  margin-right: -235.56px;
  margin-top: -9456.24px;
  position: relative;
  width: 115.49px;
}

.prosprr .text-wrapper-73 {
  color: var(--neutral-01);
  font-family: "Lexend Deca", Helvetica;
  font-size: 15.4px;
  font-weight: 400;
  letter-spacing: -0.19px;
  line-height: 21.6px;
  position: relative;
  text-align: center;
  width: 171.31px;
}

.prosprr .overlap-14 {
  height: 1638px;
  left: 0;
  position: absolute;
  top: 19478px;
  width: 1920px;
}

.prosprr .front-perspective {
  height: 922px;
  left: 818px;
  object-fit: cover;
  position: absolute;
  top: 0;
  width: 1102px;
}

.prosprr .frame-145 {
  background-color: #007662;
  height: 1577px;
  left: 0;
  overflow: hidden;
  position: absolute;
  top: 61px;
  width: 1920px;
}

.prosprr .overlap-15 {
  height: 7569px;
  left: -945px;
  position: relative;
  top: -2007px;
  width: 3374px;
}

.prosprr .homepage {
  height: 1577px;
  left: 945px;
  position: absolute;
  top: 2007px;
  width: 1063px;
}

.prosprr .homepage-2 {
  height: 1577px;
  left: 2078px;
  position: absolute;
  top: 2007px;
  width: 787px;
}

.prosprr .why-prosppr-2 {
  background-color: #ffffff;
  border: 13.7px solid;
  border-color: #ffffff52;
  border-radius: 16px;
  height: 4794px;
  left: 614px;
  overflow: hidden;
  position: absolute;
  top: 2805px;
  transform: rotate(15deg);
  width: 396px;
}

.prosprr .overlap-16 {
  height: 2249px;
  left: -147px;
  position: absolute;
  top: -92px;
  width: 659px;
}

.prosprr .overlap-17 {
  height: 758px;
  left: 0;
  position: absolute;
  top: 0;
  width: 659px;
}

.prosprr .hero-section-BG {
  height: 649px;
  left: 79px;
  position: absolute;
  top: 54px;
  transform: rotate(-15deg);
  width: 504px;
}

.prosprr .ellipse-20 {
  background: radial-gradient(50% 50% at 50% 50%, rgba(97.15, 224.19, 147.96, 0.42) 0%, rgba(217, 217, 217, 0) 88.55%);
  border-radius: 171.58px;
  filter: blur(2.18px);
  height: 343px;
  left: 0;
  position: absolute;
  top: 223px;
  width: 343px;
}

.prosprr .frame-146 {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 8.18px;
  justify-content: center;
  left: 167px;
  position: absolute;
  top: 182px;
  width: 332px;
}

.prosprr .text-wrapper-74 {
  color: var(--primary-01);
  font-family: "Lexend Deca", Helvetica;
  font-size: 12.3px;
  font-weight: 500;
  letter-spacing: -0.15px;
  line-height: 14.7px;
  margin-top: -0.49px;
  position: relative;
  text-align: center;
  width: 328.24px;
}

.prosprr .text-wrapper-75 {
  align-self: stretch;
  color: var(--neutral-04);
  font-family: "Lexend Deca", Helvetica;
  font-size: 28.6px;
  font-weight: 500;
  letter-spacing: -0.86px;
  line-height: 40.9px;
  position: relative;
  text-align: center;
}

.prosprr .input-field {
  align-items: center;
  box-shadow: 0px 4.28px 4.28px #3c3c3c1f;
  display: flex;
  left: 221px;
  overflow: hidden;
  position: absolute;
  top: 566px;
  width: 222px;
}

.prosprr .enter-mobile-number {
  flex: 1;
  flex-grow: 1;
  margin-bottom: -18.68px;
  margin-left: -2.57px;
  margin-top: -18.68px;
  position: relative;
  transform: rotate(-15deg);
}

.prosprr .join-now {
  flex: 0 0 auto;
  margin-bottom: -8.69px;
  margin-top: -8.69px;
  position: relative;
  transform: rotate(-15deg);
}

.prosprr .comma {
  height: 366px;
  left: 148px;
  position: absolute;
  top: 214px;
  transform: rotate(-15deg);
  width: 359px;
}

.prosprr .frame-147 {
  align-items: center;
  display: inline-flex;
  gap: 98.16px;
  left: 163px;
  position: absolute;
  top: 101px;
}

.prosprr .frame-148 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 12.27px;
  position: relative;
}

.prosprr .navigation-menu-24px {
  height: 24.54px !important;
  width: 24.54px !important;
}

.prosprr .navigation-menu-instance {
  height: 17px !important;
  left: 2px !important;
  top: 4px !important;
  transform: rotate(-15deg) !important;
  width: 21px !important;
}

.prosprr .vector-10 {
  height: 36.01px;
  margin-bottom: -5.32px;
  margin-top: -6.15px;
  position: relative;
  transform: rotate(-15deg);
  width: 76.91px;
}

.prosprr .download-prosppr {
  flex: 0 0 auto;
  margin-bottom: -15.23px;
  margin-right: -2.5px;
  margin-top: -15.23px;
  position: relative;
  transform: rotate(-15deg);
}

.prosprr .frame-149 {
  align-items: center;
  display: inline-flex;
  flex-direction: column;
  gap: 32.72px;
  left: 164px;
  position: absolute;
  top: 712px;
}

.prosprr .frame-150 {
  align-items: center;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 32.72px;
  position: relative;
  width: 335.38px;
}

.prosprr .frame-151 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 16.36px;
  position: relative;
}

.prosprr .headline-10 {
  color: var(--neutral-04);
  font-family: "Lexend Deca", Helvetica;
  font-size: 20.5px;
  font-weight: 600;
  letter-spacing: -0.25px;
  line-height: 24.5px;
  margin-top: -1.02px;
  position: relative;
  text-align: center;
  width: 298.57px;
}

.prosprr .text-wrapper-76 {
  color: var(--neutral-01);
  font-family: "Lexend Deca", Helvetica;
  font-size: 12.3px;
  font-weight: 400;
  letter-spacing: -0.15px;
  line-height: 17.2px;
  position: relative;
  text-align: center;
  width: 298.57px;
}

.prosprr .get-started {
  flex: 0 0 auto;
  margin-left: -2360.68px;
  margin-top: -10222.55px;
  position: relative;
  transform: rotate(-15deg);
}

.prosprr .mock-up-leaf {
  height: 326.63px;
  margin-left: -2365.68px;
  margin-top: -10165.53px;
  position: relative;
  transform: rotate(-15deg);
  width: 306.98px;
}

.prosprr .frame-152 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 32.72px;
  position: relative;
}

.prosprr .start-with {
  align-items: center;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 16.36px;
  justify-content: center;
  position: relative;
  width: 319.02px;
}

.prosprr .headline-11 {
  color: var(--neutral-04);
  font-family: "Lexend Deca", Helvetica;
  font-size: 20.5px;
  font-weight: 600;
  letter-spacing: -0.25px;
  line-height: 24.5px;
  margin-top: -1.02px;
  position: relative;
  text-align: center;
  width: 314.93px;
}

.prosprr .text-wrapper-77 {
  color: var(--neutral-01);
  font-family: "Lexend Deca", Helvetica;
  font-size: 12.3px;
  font-weight: 400;
  letter-spacing: -0.15px;
  line-height: 17.2px;
  position: relative;
  text-align: center;
  width: 309.82px;
}

.prosprr .get-started-2 {
  flex: 0 0 auto;
  margin-left: -2347.04px;
  margin-top: -10828.5px;
  position: relative;
  transform: rotate(-15deg);
}

.prosprr .mock-up-leaf-fold {
  height: 294.32px;
  margin-left: -2383.25px;
  margin-top: -10766.17px;
  position: relative;
  transform: rotate(-15deg);
  width: 288.65px;
}

.prosprr .headline-12 {
  color: var(--neutral-04);
  font-family: "Lexend Deca", Helvetica;
  font-size: 20.5px;
  font-weight: 600;
  letter-spacing: -0.25px;
  line-height: 24.5px;
  margin-top: -1.02px;
  position: relative;
  text-align: center;
  width: 296.53px;
}

.prosprr .text-wrapper-78 {
  color: var(--neutral-01);
  font-family: "Lexend Deca", Helvetica;
  font-size: 12.3px;
  font-weight: 400;
  letter-spacing: -0.15px;
  line-height: 17.2px;
  position: relative;
  text-align: center;
  width: 296.53px;
}

.prosprr .get-started-3 {
  flex: 0 0 auto;
  margin-left: -2347.04px;
  margin-top: -11350.35px;
  position: relative;
  transform: rotate(-15deg);
}

.prosprr .mock-up-leaf-2 {
  height: 241.96px;
  margin-left: -2364.02px;
  margin-top: -11287.12px;
  position: relative;
  transform: rotate(-15deg);
  width: 236.64px;
}

.prosprr .features-blocks {
  align-items: center;
  background-color: var(--neutral-04);
  display: flex;
  flex-direction: column;
  gap: 10.23px;
  justify-content: center;
  left: 0;
  padding: 24.54px 17.38px;
  position: absolute;
  top: 2889px;
  width: 368px;
}

.prosprr .frame-153 {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 32.72px;
  position: relative;
  width: 100%;
}

.prosprr .frame-154 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 8.18px;
  position: relative;
}

.prosprr .headline-13 {
  color: var(--primary-01);
  font-family: "Lexend Deca", Helvetica;
  font-size: 12.3px;
  font-weight: 600;
  letter-spacing: -0.15px;
  line-height: 14.7px;
  margin-top: -1.02px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.prosprr .headling {
  color: var(--neutral-00);
  font-family: "Lexend Deca", Helvetica;
  font-size: 20.5px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: normal;
  position: relative;
  width: fit-content;
}

.prosprr .frame-155 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 40.9px;
  position: relative;
  width: 100%;
}

.prosprr .block-one {
  align-items: flex-start;
  display: flex;
  flex: 0 0 auto;
  gap: 16.36px;
  position: relative;
  width: 303.68px;
}

.prosprr .signup-icon {
  height: 40.9px;
  margin-left: -2469.75px;
  margin-top: -12710.25px;
  position: relative;
  transform: rotate(-15deg);
  width: 40.9px;
}

.prosprr .frame-156 {
  align-items: flex-start;
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-grow: 1;
  gap: 12.27px;
  position: relative;
}

.prosprr .download-sign-up {
  align-self: stretch;
  color: var(--neutral-00);
  font-family: "Lexend Deca", Helvetica;
  font-size: 16.4px;
  font-weight: 500;
  letter-spacing: -0.57px;
  line-height: normal;
  margin-top: -1.02px;
  position: relative;
}

.prosprr .text-wrapper-79 {
  align-self: stretch;
  color: var(--neutral-00);
  font-family: "Lexend Deca", Helvetica;
  font-size: 12.3px;
  font-weight: 300;
  letter-spacing: 0;
  line-height: 18.4px;
  position: relative;
}

.prosprr .div-5 {
  align-items: flex-start;
  display: flex;
  flex: 0 0 auto;
  gap: 8.18px;
  position: relative;
  width: 303.68px;
}

.prosprr .link-your-account {
  height: 40.9px;
  margin-left: -2469.75px;
  margin-top: -12819.42px;
  position: relative;
  transform: rotate(-15deg);
  width: 40.9px;
}

.prosprr .text-wrapper-80 {
  align-self: stretch;
  color: var(--neutral-00);
  font-family: "Lexend Deca", Helvetica;
  font-size: 16.4px;
  font-weight: 500;
  letter-spacing: -0.16px;
  line-height: normal;
  margin-top: -1.02px;
  position: relative;
}

.prosprr .text-wrapper-81 {
  align-self: stretch;
  color: var(--neutral-00);
  font-family: "Plus Jakarta Sans", Helvetica;
  font-size: 12.3px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 18.4px;
  position: relative;
}

.prosprr .get-investing-from {
  height: 40.9px;
  margin-left: -2469.75px;
  margin-top: -12946.59px;
  position: relative;
  transform: rotate(-15deg);
  width: 40.9px;
}

.prosprr .text-wrapper-82 {
  color: var(--neutral-00);
  font-family: "Lexend Deca", Helvetica;
  font-size: 16.4px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1.02px;
  position: relative;
  width: 245.4px;
}

.prosprr .prosperity-logo {
  height: 40.9px;
  margin-left: -2469.75px;
  margin-top: -13073.76px;
  position: relative;
  transform: rotate(-15deg);
  width: 40.9px;
}

.prosprr .text-wrapper-83 {
  color: var(--neutral-00);
  font-family: "Lexend Deca", Helvetica;
  font-size: 16.4px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: normal;
  margin-right: -12.27px;
  margin-top: -1.02px;
  position: relative;
  width: 266.87px;
}

.prosprr .frame-157 {
  height: 670px;
  left: 0;
  overflow: hidden;
  position: absolute;
  top: 2219px;
  width: 368px;
}

.prosprr .rectangle-7 {
  height: 812px;
  left: -2359px;
  position: absolute;
  top: -11990px;
  transform: rotate(-15deg);
  width: 374px;
}

.prosprr .overlap-18 {
  height: 652px;
  left: 16px;
  position: absolute;
  top: 25px;
  width: 350px;
}

.prosprr .frame-158 {
  align-items: center;
  display: inline-flex;
  flex-direction: column;
  gap: 32.72px;
  left: 0;
  position: absolute;
  top: 0;
}

.prosprr .headline-14 {
  color: var(--neutral-00);
  font-family: "Lexend Deca", Helvetica;
  font-size: 20.5px;
  font-weight: 600;
  letter-spacing: -0.25px;
  line-height: 24.5px;
  margin-top: -0.41px;
  position: relative;
  text-align: center;
  width: 312.89px;
}

.prosprr .headline-15 {
  color: var(--accent-01);
  font-family: "Lexend Deca", Helvetica;
  font-size: 12.3px;
  font-weight: 400;
  letter-spacing: -0.15px;
  line-height: 17.2px;
  position: relative;
  text-align: center;
  width: 300.62px;
}

.prosprr .frame-159 {
  align-items: flex-start;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 24.54px;
  position: relative;
  width: 335.38px;
}

.prosprr .div-6 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  gap: 12.27px;
  position: relative;
  width: 100%;
}

.prosprr .icon-check {
  height: 24.54px;
  margin-left: -2470.57px;
  margin-top: -12098.11px;
  position: relative;
  transform: rotate(-15deg);
  width: 24.54px;
}

.prosprr .frame-160 {
  align-items: flex-start;
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-grow: 1;
  gap: 15.37px;
  position: relative;
}

.prosprr .start-with-2 {
  align-self: stretch;
  color: var(--accent-01);
  font-family: "Lexend Deca", Helvetica;
  font-size: 16.4px;
  font-weight: 400;
  letter-spacing: -0.2px;
  line-height: 19.6px;
  position: relative;
}

.prosprr .span {
  font-weight: 500;
}

.prosprr .text-wrapper-84 {
  color: #e9ecf0;
  font-family: "Lexend Deca", Helvetica;
  font-size: 16.4px;
  font-weight: 400;
  letter-spacing: -0.2px;
  line-height: 19.6px;
}

.prosprr .text-wrapper-85 {
  color: var(--accent-03);
  font-family: "Lexend Deca", Helvetica;
  font-size: 12.3px;
  font-weight: 400;
  letter-spacing: -0.15px;
  line-height: 17.2px;
  position: relative;
  width: 253.58px;
}

.prosprr .feature-block {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  gap: 12.27px;
  position: relative;
  width: 100%;
}

.prosprr .icon-check-2 {
  height: 24.54px;
  margin-left: -2470.57px;
  margin-top: -12226.02px;
  position: relative;
  transform: rotate(-15deg);
  width: 24.54px;
}

.prosprr .frame-161 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-grow: 1;
  gap: 15.37px;
  position: relative;
}

.prosprr .text-wrapper-86 {
  align-self: stretch;
  color: var(--accent-01);
  font-family: "Lexend Deca", Helvetica;
  font-size: 16.4px;
  font-weight: 500;
  letter-spacing: -0.2px;
  line-height: 19.6px;
  margin-top: -0.77px;
  position: relative;
}

.prosprr .feature-block-2 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  gap: 12.3px;
  position: relative;
  width: 100%;
}

.prosprr .icon-check-3 {
  height: 24.54px;
  margin-left: -2470.57px;
  margin-top: -12275.1px;
  position: relative;
  transform: rotate(-15deg);
  width: 24.54px;
}

.prosprr .text-wrapper-87 {
  color: var(--accent-01);
  font-family: "Lexend Deca", Helvetica;
  font-size: 16.4px;
  font-weight: 500;
  letter-spacing: -0.2px;
  line-height: 19.6px;
  margin-right: -70.32px;
  margin-top: -0.77px;
  position: relative;
  width: 368.86px;
}

.prosprr .frame-162 {
  height: 352px;
  left: 0;
  position: absolute;
  top: 301px;
  width: 350px;
}

.prosprr .overlap-19 {
  height: 347px;
  left: -2454px;
  position: absolute;
  top: -12267px;
  width: 394px;
}

.prosprr .vector-11 {
  height: 178px;
  left: 81px;
  position: absolute;
  top: 139px;
  transform: rotate(-15deg);
  width: 232px;
}

.prosprr .cofee-mockup {
  height: 269px;
  left: 29px;
  position: absolute;
  top: 39px;
  transform: rotate(-15deg);
  width: 336px;
}

.prosprr .group-16 {
  height: 99px;
  left: 209px;
  position: absolute;
  top: 18px;
  width: 90px;
}

.prosprr .overlap-group-9 {
  height: 99px;
  left: 808px;
  position: relative;
  top: -12539px;
}

.prosprr .vector-12 {
  height: 67px;
  left: 7px;
  position: absolute;
  top: 7px;
  width: 70px;
}

.prosprr .cofee-mockup-2 {
  height: 99px;
  left: 0;
  position: absolute;
  top: 0;
  width: 90px;
}

.prosprr .frame-163 {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 24.54px;
  left: 0;
  position: absolute;
  top: 3489px;
  width: 370px;
}

.prosprr .frame-164 {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 8.18px;
  justify-content: center;
  position: relative;
  width: 100%;
}

.prosprr .headline-16 {
  color: var(--primary-03);
  font-family: "Plus Jakarta Sans", Helvetica;
  font-size: 12.3px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -0.41px;
  position: relative;
  text-align: center;
  width: fit-content;
}

.prosprr .headline-17 {
  color: #1a1a1a;
  font-family: "Lexend Deca", Helvetica;
  font-size: 20.5px;
  font-weight: 600;
  letter-spacing: -0.25px;
  line-height: 24.5px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

.prosprr .frame-165 {
  align-items: center;
  display: flex;
  flex: 0 0 auto;
  gap: 24.54px;
  justify-content: center;
  padding: 4.96px 39.66px;
  position: relative;
  width: 367.08px;
}

.prosprr .how-compounding-5 {
  align-items: center;
  background-color: #f8f9fa;
  border: 0.41px solid;
  border-color: var(--accent-02);
  border-radius: 3.31px;
  box-shadow: -2.07px 1.24px 4.96px #0e56491f;
  display: flex;
  flex-direction: column;
  gap: 6.61px;
  margin-bottom: -0.41px;
  margin-left: -235.37px;
  margin-top: -0.41px;
  opacity: 0.6;
  position: relative;
  width: 237.03px;
}

.prosprr .frame-166 {
  align-items: flex-start;
  align-self: stretch;
  border-radius: 4.96px;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  overflow: hidden;
  position: relative;
  width: 100%;
}

.prosprr .image-10 {
  align-self: stretch;
  height: 124.75px;
  margin-left: -2251.08px;
  margin-top: -13308.4px;
  object-fit: cover;
  position: relative;
  transform: rotate(-15deg);
  width: 100%;
}

.prosprr .frame-167 {
  align-items: flex-start;
  align-self: stretch;
  border-radius: 4.96px;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 6.61px;
  justify-content: center;
  padding: 9.92px 9.92px 19.83px;
  position: relative;
  width: 100%;
}

.prosprr .text-wrapper-88 {
  align-self: stretch;
  color: var(--neutral-04);
  font-family: "Lexend Deca", Helvetica;
  font-size: 16.4px;
  font-weight: 500;
  letter-spacing: -0.2px;
  line-height: 19.6px;
  margin-top: -0.41px;
  position: relative;
}

.prosprr .frame-168 {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 13.22px;
  justify-content: center;
  position: relative;
  width: 100%;
}

.prosprr .text-wrapper-89 {
  align-self: stretch;
  color: var(--neutral-03);
  font-family: "Lexend Deca", Helvetica;
  font-size: 12.3px;
  font-weight: 300;
  height: 40.9px;
  letter-spacing: -0.15px;
  line-height: 17.2px;
  margin-top: -0.41px;
  position: relative;
}

.prosprr .read-more-4 {
  flex: 0 0 auto;
  margin-left: -2191.39px;
  margin-top: -13433.17px;
  position: relative;
  transform: rotate(-15deg);
}

.prosprr .how-compounding-6 {
  align-items: center;
  background-color: #f8f9fa;
  border: 0.41px solid;
  border-color: var(--accent-02);
  border-radius: 3.31px;
  box-shadow: -2.07px 1.24px 4.96px #0e56491f;
  display: flex;
  flex-direction: column;
  gap: 6.61px;
  margin-bottom: -0.41px;
  margin-top: -0.41px;
  position: relative;
  width: 237.03px;
}

.prosprr .image-11 {
  align-self: stretch;
  height: 124.75px;
  margin-left: -2511.82px;
  margin-top: -13308.4px;
  object-fit: cover;
  position: relative;
  transform: rotate(-15deg);
  width: 100%;
}

.prosprr .read-more-5 {
  flex: 0 0 auto;
  margin-left: -2452.13px;
  margin-top: -13433.16px;
  position: relative;
  transform: rotate(-15deg);
}

.prosprr .how-compounding-7 {
  align-items: center;
  background-color: #f8f9fa;
  border: 0.41px solid;
  border-color: var(--accent-02);
  border-radius: 3.31px;
  box-shadow: -2.07px 1.24px 4.96px #0e56491f;
  display: flex;
  flex-direction: column;
  gap: 6.61px;
  margin-bottom: -0.41px;
  margin-right: -235.37px;
  margin-top: -0.41px;
  opacity: 0.6;
  position: relative;
  width: 237.03px;
}

.prosprr .image-12 {
  align-self: stretch;
  height: 124.75px;
  margin-left: -2772.56px;
  margin-top: -13308.4px;
  object-fit: cover;
  position: relative;
  transform: rotate(-15deg);
  width: 100%;
}

.prosprr .read-more-6 {
  flex: 0 0 auto;
  margin-left: -2712.87px;
  margin-top: -13433.16px;
  position: relative;
  transform: rotate(-15deg);
}

.prosprr .frame-169 {
  align-items: center;
  background-color: #101728;
  display: inline-flex;
  flex-direction: column;
  gap: 16.36px;
  left: 0;
  padding: 24.54px 16.36px;
  position: absolute;
  top: 4347px;
}

.prosprr .frame-170 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 16.36px;
  position: relative;
}

.prosprr .frame-171 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 9.24px;
  position: relative;
}

.prosprr .prosppr-logo-2 {
  height: 32.72px;
  margin-left: -2470.9px;
  margin-top: -14094.78px;
  position: relative;
  transform: rotate(-15deg);
  width: 106.34px;
}

.prosprr .frame-172 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 12.27px;
  position: relative;
  width: 100%;
}

.prosprr .text-wrapper-90 {
  color: var(--accent-01);
  font-family: "Lexend Deca", Helvetica;
  font-size: 16.4px;
  font-weight: 600;
  letter-spacing: -0.16px;
  line-height: normal;
  margin-top: -1.02px;
  position: relative;
  width: fit-content;
}

.prosprr .image-13 {
  height: 44.99px;
  margin-left: -2469.8px;
  margin-top: -14188.22px;
  position: relative;
  transform: rotate(-15deg);
  width: 134.97px;
}

.prosprr .image-14 {
  height: 44.99px;
  margin-left: -2327.58px;
  margin-top: -14226.33px;
  position: relative;
  transform: rotate(-15deg);
  width: 134.97px;
}

.prosprr .frame-173 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 30.68px;
  position: relative;
}

.prosprr .text-wrapper-91 {
  color: var(--accent-01);
  font-family: "Lexend Deca", Helvetica;
  font-size: 14.3px;
  font-weight: 400;
  letter-spacing: -0.14px;
  line-height: normal;
  margin-top: -1.02px;
  position: relative;
  width: fit-content;
}

.prosprr .text-wrapper-92 {
  color: var(--accent-01);
  font-family: "Lexend Deca", Helvetica;
  font-size: 14.3px;
  font-weight: 400;
  letter-spacing: -0.14px;
  line-height: normal;
  position: relative;
  width: fit-content;
}

.prosprr .divider-4 {
  height: 1px;
  margin-left: -2413.31px;
  margin-top: -13878.55px;
  position: relative;
  transform: rotate(-15deg);
  width: 335.38px;
}

.prosprr .frame-174 {
  align-items: center;
  display: flex;
  flex: 0 0 auto;
  justify-content: space-between;
  position: relative;
  width: 335.38px;
}

.prosprr .text-wrapper-93 {
  color: var(--neutral-00);
  font-family: "Figtree", Helvetica;
  font-size: 14.3px;
  font-weight: 700;
  letter-spacing: -0.14px;
  line-height: normal;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.prosprr .social-buttons-2 {
  flex: 0 0 auto;
  margin-left: -4593.8px;
  margin-top: -14422.12px;
  position: relative;
  transform: rotate(-15deg);
}

.prosprr .divider-5 {
  height: 1px;
  margin-left: -2398.49px;
  margin-top: -13823.24px;
  position: relative;
  transform: rotate(-15deg);
  width: 335.38px;
}

.prosprr .frame-175 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 15.34px;
  position: relative;
}

.prosprr .text-wrapper-94 {
  color: var(--neutral-01);
  font-family: "Figtree", Helvetica;
  font-size: 10.2px;
  font-weight: 400;
  letter-spacing: -0.1px;
  line-height: normal;
  margin-top: -1.02px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.prosprr .frame-176 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 12.27px;
  position: relative;
}

.prosprr .text-wrapper-95 {
  color: var(--neutral-01);
  font-family: "Figtree", Helvetica;
  font-size: 12.3px;
  font-weight: 400;
  letter-spacing: -0.12px;
  line-height: normal;
  margin-top: -1.02px;
  position: relative;
  width: fit-content;
}

.prosprr .frame-177 {
  height: 428px;
  left: 16px;
  position: absolute;
  top: 3886px;
  width: 353px;
}

.prosprr .frame-178 {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 16.36px;
  justify-content: center;
  left: 0;
  position: absolute;
  top: 0;
  width: 335px;
}

.prosprr .text-wrapper-96 {
  color: #000000;
  font-family: "Lexend Deca", Helvetica;
  font-size: 20.5px;
  font-weight: 600;
  letter-spacing: -0.25px;
  line-height: 24.5px;
  margin-top: -1.02px;
  position: relative;
  text-align: center;
  width: 304.71px;
}

.prosprr .text-wrapper-97 {
  color: var(--neutral-01);
  font-family: "Lexend Deca", Helvetica;
  font-size: 12.3px;
  font-weight: 400;
  letter-spacing: -0.15px;
  line-height: 17.2px;
  position: relative;
  text-align: center;
  width: 295.5px;
}

.prosprr .frame-179 {
  height: 317px;
  left: 188px;
  position: absolute;
  top: 111px;
  width: 196px;
}

.prosprr .leaf-3 {
  height: 75px;
  left: -2401px;
  object-fit: cover;
  position: absolute;
  top: -13539px;
  transform: rotate(-15deg);
  width: 109px;
}

.prosprr .element-3 {
  height: 302px;
  left: -2625px;
  position: absolute;
  top: -13730px;
  transform: rotate(-15deg);
  width: 141px;
}

.prosprr .frame-180 {
  align-items: center;
  display: inline-flex;
  flex-direction: column;
  gap: 32.72px;
  left: 26px;
  position: absolute;
  top: 149px;
}

.prosprr .frame-181 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 7.34px;
  justify-content: center;
  position: relative;
}

.prosprr .android-store-2 {
  align-self: stretch;
  height: 30.99px;
  margin-left: -2513.24px;
  margin-top: -13757.63px;
  position: relative;
  transform: rotate(-15deg);
  width: 100%;
}

.prosprr .apple-store-2 {
  height: 32.31px;
  margin-left: -2503.15px;
  margin-top: -13720.63px;
  position: relative;
  transform: rotate(-15deg);
  width: 100.88px;
}

.prosprr .frame-182 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 12.27px;
  justify-content: center;
  position: relative;
}

.prosprr .image-15 {
  height: 90.45px;
  margin-left: -2467.61px;
  margin-top: -13866.31px;
  position: relative;
  transform: rotate(-15deg);
  width: 90.45px;
}

.prosprr .text-wrapper-98 {
  color: var(--neutral-01);
  font-family: "Lexend Deca", Helvetica;
  font-size: 12.1px;
  font-weight: 400;
  letter-spacing: -0.15px;
  line-height: 16.9px;
  position: relative;
  text-align: center;
  width: 134.17px;
}

.prosprr .why-crypto-SIP-3 {
  background-color: #ffffff;
  border: 13.7px solid;
  border-color: #ffffff52;
  border-radius: 16px;
  height: 3903px;
  left: 1862px;
  overflow: hidden;
  position: absolute;
  top: 410px;
  transform: rotate(15deg);
  width: 396px;
}

.prosprr .hero-section-BG-2 {
  height: 460px;
  left: -2874px;
  position: absolute;
  top: -7143px;
  transform: rotate(-15deg);
  width: 368px;
}

.prosprr .overlap-20 {
  height: 382px;
  left: 0;
  position: absolute;
  top: 78px;
  width: 368px;
}

.prosprr .component-2 {
  height: 382px;
  left: 0;
  overflow: hidden;
  position: absolute;
  top: 0;
  width: 368px;
}

.prosprr .overlap-group-10 {
  height: 405px;
  left: -147px;
  position: relative;
  top: 12px;
  width: 500px;
}

.prosprr .ellipse-21 {
  background: radial-gradient(50% 50% at 50% 50%, rgba(97.15, 224.19, 147.96, 0.42) 0%, rgba(217, 217, 217, 0) 88.55%);
  border-radius: 171.58px;
  filter: blur(2.18px);
  height: 343px;
  left: 0;
  position: absolute;
  top: 61px;
  width: 343px;
}

.prosprr .frame-183 {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 16.36px;
  justify-content: center;
  left: 164px;
  padding: 0px 12.27px;
  position: absolute;
  top: 0;
  width: 336px;
}

.prosprr .text-wrapper-99 {
  align-self: stretch;
  color: var(--primary-01);
  font-family: "Lexend Deca", Helvetica;
  font-size: 12.3px;
  font-weight: 500;
  letter-spacing: -0.15px;
  line-height: 14.7px;
  margin-top: -1.02px;
  position: relative;
  text-align: center;
}

.prosprr .most-preferred-2 {
  color: var(--neutral-04);
  font-family: "Lexend Deca", Helvetica;
  font-size: 28.6px;
  font-weight: 500;
  letter-spacing: -0.86px;
  line-height: 32.7px;
  position: relative;
  text-align: center;
  width: 274.03px;
}

.prosprr .text-wrapper-100 {
  align-self: stretch;
  color: var(--neutral-01);
  font-family: "Lexend Deca", Helvetica;
  font-size: 12.3px;
  font-weight: 400;
  letter-spacing: -0.15px;
  line-height: 17.2px;
  position: relative;
  text-align: center;
}

.prosprr .get-started-4 {
  flex: 0 0 auto;
  margin-left: -2805.19px;
  margin-top: -7071.42px;
  position: relative;
  transform: rotate(-15deg);
}

.prosprr .frame-184 {
  height: 168px;
  left: 0;
  position: absolute;
  top: 215px;
  width: 368px;
}

.prosprr .overlap-21 {
  border-radius: 141.11px;
  height: 282px;
  left: -133px;
  position: absolute;
  top: -47px;
  width: 282px;
}

.prosprr .ellipse-22 {
  background: radial-gradient(
    50% 50% at 50% 50%,
    rgba(97.15, 224.19, 147.96, 0.42) 1.63%,
    rgba(217, 217, 217, 0) 53.12%
  );
  border-radius: 141.11px;
  filter: blur(1.79px);
  height: 282px;
  left: 0;
  position: absolute;
  top: 0;
  width: 282px;
}

.prosprr .frame-185 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  height: 18px;
  left: 113px;
  opacity: 0.77;
  position: absolute;
  top: 127px;
  width: 17px;
}

.prosprr .headline-18 {
  align-self: stretch;
  color: var(--neutral-01);
  flex: 1;
  font-family: "Lexend Deca", Helvetica;
  font-size: 3.8px;
  font-weight: 500;
  letter-spacing: -0.05px;
  line-height: 4.5px;
  margin-top: -0.19px;
  position: relative;
}

.prosprr .headline-19 {
  align-self: stretch;
  color: var(--primary-03);
  flex: 1;
  font-family: "Lexend Deca", Helvetica;
  font-size: 5.7px;
  font-weight: 600;
  letter-spacing: -0.07px;
  line-height: 6.8px;
  margin-top: -4.53px;
  position: relative;
}

.prosprr .frame-186 {
  align-items: flex-start;
  display: inline-flex;
  flex-direction: column;
  gap: 2.05px;
  left: 183px;
  opacity: 0.77;
  position: absolute;
  top: 141px;
}

.prosprr .headline-20 {
  color: var(--neutral-01);
  font-family: "Lexend Deca", Helvetica;
  font-size: 6.1px;
  font-weight: 500;
  letter-spacing: -0.07px;
  line-height: 7.4px;
  margin-top: -0.19px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.prosprr .headline-21 {
  color: var(--primary-03);
  font-family: "Lexend Deca", Helvetica;
  font-size: 8.2px;
  font-weight: 600;
  letter-spacing: -0.1px;
  line-height: 9.8px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.prosprr .group-17 {
  height: 38px;
  left: 173px;
  position: absolute;
  top: 146px;
  width: 14px;
}

.prosprr .overlap-group-11 {
  border-radius: 6.98px;
  height: 14px;
  left: 0;
  position: absolute;
  top: 24px;
  width: 14px;
}

.prosprr .ellipse-23 {
  background: radial-gradient(
    50% 50% at 50% 50%,
    rgba(46.11, 238, 14.88, 0.67) 0%,
    rgba(42.1, 255, 7.44, 0.21) 14.56%,
    rgba(41.02, 215.69, 12.58, 0) 32.05%
  );
  border-radius: 6.98px;
  height: 14px;
  left: 0;
  position: absolute;
  top: 0;
  width: 14px;
}

.prosprr .ellipse-24 {
  background-color: var(--primary-03);
  border-radius: 0.89px;
  height: 2px;
  left: 6px;
  position: absolute;
  top: 8px;
  width: 2px;
}

.prosprr .vector-13 {
  height: 31px;
  left: -878px;
  position: absolute;
  top: -7893px;
  width: 1px;
}

.prosprr .ellipse-25 {
  background-color: var(--primary-03);
  border-radius: 0.89px;
  height: 2px;
  left: 6px;
  position: absolute;
  top: 0;
  width: 2px;
}

.prosprr .group-18 {
  height: 38px;
  left: 102px;
  position: absolute;
  top: 132px;
  width: 14px;
}

.prosprr .vector-14 {
  height: 31px;
  left: -884px;
  position: absolute;
  top: -7875px;
  width: 1px;
}

.prosprr .group-19 {
  height: 38px;
  left: 244px;
  position: absolute;
  top: 94px;
  width: 14px;
}

.prosprr .vector-15 {
  height: 31px;
  left: -889px;
  position: absolute;
  top: -7913px;
  width: 1px;
}

.prosprr .frame-187 {
  align-items: flex-start;
  display: inline-flex;
  flex-direction: column;
  gap: 2.05px;
  left: 255px;
  opacity: 0.77;
  position: absolute;
  top: 89px;
}

.prosprr .overlap-22 {
  background: radial-gradient(50% 50% at 50% 50%, rgba(246.5, 231.5, 96.55, 0.19) 0%, rgba(217, 217, 217, 0) 100%);
  border-radius: 116.75px;
  height: 233px;
  left: 252px;
  position: absolute;
  top: -45px;
  width: 233px;
}

.prosprr .overlap-23 {
  height: 41px;
  left: 18px;
  position: absolute;
  top: 104px;
  width: 38px;
}

.prosprr .group-20 {
  height: 37px;
  left: 0;
  position: absolute;
  top: 4px;
  width: 14px;
}

.prosprr .overlap-24 {
  border-radius: 6.98px/6.82px;
  height: 14px;
  left: 0;
  position: absolute;
  top: 23px;
  width: 14px;
}

.prosprr .ellipse-26 {
  background: radial-gradient(
    50% 50% at 50% 50%,
    rgba(46.11, 238, 14.88, 0.67) 0%,
    rgba(42.1, 255, 7.44, 0.21) 14.56%,
    rgba(41.02, 215.69, 12.58, 0) 32.05%
  );
  border-radius: 6.98px/6.82px;
  height: 14px;
  left: 0;
  position: absolute;
  top: 0;
  width: 14px;
}

.prosprr .ellipse-27 {
  background-color: var(--primary-03);
  border-radius: 0.89px/0.87px;
  height: 2px;
  left: 6px;
  position: absolute;
  top: 8px;
  width: 2px;
}

.prosprr .vector-16 {
  height: 30px;
  left: -880px;
  position: absolute;
  top: -7954px;
  width: 1px;
}

.prosprr .ellipse-28 {
  background-color: var(--primary-03);
  border-radius: 0.89px/0.87px;
  height: 2px;
  left: 6px;
  position: absolute;
  top: 0;
  width: 2px;
}

.prosprr .frame-188 {
  align-items: flex-start;
  display: inline-flex;
  flex-direction: column;
  gap: 2.05px;
  left: 10px;
  opacity: 0.77;
  position: absolute;
  top: 0;
}

.prosprr .overlap-25 {
  height: 40px;
  left: 89px;
  position: absolute;
  top: 99px;
  width: 40px;
}

.prosprr .group-21 {
  height: 37px;
  left: 0;
  position: absolute;
  top: 3px;
  width: 14px;
}

.prosprr .vector-17 {
  height: 30px;
  left: -879px;
  position: absolute;
  top: -7972px;
  width: 1px;
}

.prosprr .overlap-26 {
  height: 30px;
  left: 200px;
  position: absolute;
  top: 42px;
  width: 38px;
}

.prosprr .group-22 {
  height: 26px;
  left: 0;
  position: absolute;
  top: 4px;
  width: 14px;
}

.prosprr .overlap-27 {
  border-radius: 6.98px;
  height: 14px;
  left: 0;
  position: absolute;
  top: 12px;
  width: 14px;
}

.prosprr .vector-18 {
  height: 19px;
  left: -888px;
  position: absolute;
  top: -7937px;
  width: 1px;
}

.prosprr .frame-189 {
  align-items: flex-start;
  display: inline-flex;
  flex-direction: column;
  gap: 2.05px;
  left: 12px;
  opacity: 0.77;
  position: absolute;
  top: 0;
}

.prosprr .vector-19 {
  height: 70px;
  left: -2957px;
  position: absolute;
  top: -7364px;
  transform: rotate(-15deg);
  width: 431px;
}

.prosprr .frame-190 {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 24.54px;
  left: 0;
  position: absolute;
  top: 2597px;
  width: 370px;
}

.prosprr .image-16 {
  align-self: stretch;
  height: 181.63px;
  margin-right: -9.65px;
  margin-top: -47.22px;
  object-fit: cover;
  position: relative;
  transform: rotate(-15deg);
  width: 100%;
}

.prosprr .read-more-7 {
  flex: 0 0 auto;
  margin-left: -2661.65px;
  margin-top: -9920.19px;
  position: relative;
  transform: rotate(-15deg);
}

.prosprr .image-17 {
  align-self: stretch;
  height: 181.63px;
  margin-left: -12.12px;
  margin-right: -12.12px;
  margin-top: -28.44px;
  object-fit: cover;
  position: relative;
  transform: rotate(-15deg);
  width: 100%;
}

.prosprr .read-more-8 {
  flex: 0 0 auto;
  margin-bottom: -9.96px;
  position: relative;
  transform: rotate(-15deg);
}

.prosprr .image-18 {
  align-self: stretch;
  height: 181.63px;
  margin-left: -9.27px;
  margin-top: -6.82px;
  object-fit: cover;
  position: relative;
  transform: rotate(-15deg);
  width: 100%;
}

.prosprr .read-more-9 {
  flex: 0 0 auto;
  margin-bottom: -8.01px;
  position: relative;
  transform: rotate(-15deg);
}

.prosprr .frame-191 {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 16.36px;
  justify-content: center;
  left: 16px;
  position: absolute;
  top: 2994px;
  width: 335px;
}

.prosprr .frame-192 {
  height: 317px;
  left: 205px;
  position: absolute;
  top: 3105px;
  width: 196px;
}

.prosprr .leaf-4 {
  height: 75px;
  left: -2872px;
  object-fit: cover;
  position: absolute;
  top: -10026px;
  transform: rotate(-15deg);
  width: 109px;
}

.prosprr .element-4 {
  height: 90px;
  left: -67px;
  position: absolute;
  top: -9px;
  transform: rotate(-15deg);
  width: 214px;
}

.prosprr .frame-193 {
  align-items: center;
  background-color: #101728;
  display: inline-flex;
  flex-direction: column;
  gap: 16.36px;
  left: 0;
  padding: 24.54px 16.36px;
  position: absolute;
  top: 3455px;
}

.prosprr .prosppr-logo-3 {
  height: 32.72px;
  margin-left: -2941.16px;
  margin-top: -10581.8px;
  position: relative;
  transform: rotate(-15deg);
  width: 106.34px;
}

.prosprr .image-19 {
  height: 44.99px;
  margin-left: -2940.06px;
  margin-top: -10675.25px;
  position: relative;
  transform: rotate(-15deg);
  width: 134.97px;
}

.prosprr .image-20 {
  height: 44.99px;
  margin-left: -2797.84px;
  margin-top: -10713.36px;
  position: relative;
  transform: rotate(-15deg);
  width: 134.97px;
}

.prosprr .divider-6 {
  height: 1px;
  margin-left: -2883.57px;
  margin-top: -10365.58px;
  position: relative;
  transform: rotate(-15deg);
  width: 335.38px;
}

.prosprr .social-buttons-3 {
  flex: 0 0 auto;
  margin-left: -5535.07px;
  margin-top: -10909.15px;
  position: relative;
  transform: rotate(-15deg);
}

.prosprr .divider-7 {
  height: 1px;
  margin-left: -2868.75px;
  margin-top: -10310.27px;
  position: relative;
  transform: rotate(-15deg);
  width: 335.38px;
}

.prosprr .thumbs-up-2 {
  -webkit-backdrop-filter: blur(15.34px) brightness(100%);
  backdrop-filter: blur(15.34px) brightness(100%);
  background-color: #f2f6f18f;
  border: 4.18px solid;
  border-color: transparent;
  border-image: linear-gradient(
      to bottom,
      rgba(44.41, 140.25, 94.53, 0) 14.38%,
      rgba(66.07, 158.51, 102.4, 0.29) 45.13%,
      rgb(120, 204, 122) 100%
    )
    1;
  border-radius: 30.54px;
  box-shadow: 2.05px 3.58px 9.54px #0e564938;
  height: 115px;
  left: 535px;
  overflow: hidden;
  position: absolute;
  top: 1439px;
  width: 115px;
}

.prosprr .image-21 {
  height: 78px;
  left: 14px;
  object-fit: cover;
  position: absolute;
  top: 24px;
  transform: rotate(-15deg);
  width: 89px;
}

.prosprr .frame-194 {
  align-items: center;
  display: inline-flex;
  flex-direction: column;
  gap: 32.72px;
  left: 0;
  position: absolute;
  top: 493px;
}

.prosprr .frame-195 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 32.72px;
  position: relative;
}

.prosprr .frame-196 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 16.36px;
  padding: 0px 16.36px;
  position: relative;
}

.prosprr .get-started-5 {
  flex: 0 0 auto;
  margin-left: -2815.14px;
  margin-top: -7478.65px;
  position: relative;
  transform: rotate(-15deg);
}

.prosprr .group-23 {
  height: 259.11px;
  position: relative;
  width: 281.37px;
}

.prosprr .overlap-28 {
  height: 244px;
  left: 0;
  position: absolute;
  top: 0;
  width: 255px;
}

.prosprr .ellipse-29 {
  background: linear-gradient(180deg, rgb(0, 118, 98) 0%, rgb(120, 204, 122) 100%);
  border-radius: 121.83px;
  height: 244px;
  left: 0;
  position: absolute;
  top: 0;
  width: 244px;
}

.prosprr .getpaidstock-wrapper {
  background-color: #fcfffb8f;
  border: 1.69px solid;
  border-color: transparent;
  border-image: linear-gradient(
      to bottom,
      rgba(44.41, 140.25, 94.53, 0) 1.65%,
      rgba(66.07, 158.51, 102.4, 0.29) 33.26%,
      rgb(120, 204, 122) 100%
    )
    1;
  border-radius: 12.33px;
  box-shadow: -0.41px 1.24px 3.85px 0.83px #0e564938;
  height: 46px;
  left: 28px;
  overflow: hidden;
  position: absolute;
  top: 52px;
  width: 46px;
}

.prosprr .getpaidstock-2 {
  height: 40px;
  left: -854px;
  object-fit: cover;
  position: absolute;
  top: -8362px;
  width: 40px;
}

.prosprr .cryptobasket-2 {
  -webkit-backdrop-filter: blur(8.26px) brightness(100%);
  backdrop-filter: blur(8.26px) brightness(100%);
  background-color: #f7fef88f;
  border: 1.03px solid;
  border-color: transparent;
  border-image: linear-gradient(to bottom, rgb(247.56, 255, 253.76), rgb(120, 204, 122)) 1;
  border-radius: 5.75px;
  box-shadow: -0.36px 2.55px 2.87px #0e564938;
  height: 82px;
  left: 169px;
  position: absolute;
  top: 7px;
  width: 86px;
}

.prosprr .overlap-group-12 {
  height: 12px;
  left: 0;
  position: absolute;
  top: 68px;
  width: 84px;
}

.prosprr .rectangle-8 {
  background-color: #ffffff;
  border-radius: 0px 5.75px 0px 5.75px;
  height: 12px;
  left: 33px;
  position: absolute;
  top: 0;
  transform: rotate(180deg);
  width: 51px;
}

.prosprr .text-wrapper-101 {
  color: var(--primary-03);
  font-family: "Lexend Deca", Helvetica;
  font-size: 6.1px;
  font-weight: 400;
  left: 54px;
  letter-spacing: -0.07px;
  line-height: 8.6px;
  position: absolute;
  top: 2px;
  white-space: nowrap;
}

.prosprr .rectangle-9 {
  background-color: var(--primary-02);
  border-radius: 0px 5.75px 5.75px 5.75px;
  height: 12px;
  left: 0;
  position: absolute;
  top: 0;
  width: 42px;
}

.prosprr .text-wrapper-102 {
  color: var(--neutral-00);
  font-family: "Lexend Deca", Helvetica;
  font-size: 6.1px;
  font-weight: 400;
  left: 14px;
  letter-spacing: -0.07px;
  line-height: 8.6px;
  position: absolute;
  top: 2px;
  white-space: nowrap;
}

.prosprr .text-wrapper-103 {
  color: var(--neutral-05);
  font-family: "Lexend", Helvetica;
  font-size: 6.1px;
  font-weight: 400;
  left: 4px;
  letter-spacing: 0.03px;
  line-height: normal;
  position: absolute;
  top: 10px;
}

.prosprr .text-wrapper-104 {
  color: var(--neutral-05);
  font-family: "Lexend", Helvetica;
  font-size: 6.1px;
  font-weight: 400;
  left: 4px;
  letter-spacing: 0.03px;
  line-height: normal;
  position: absolute;
  top: 37px;
}

.prosprr .frame-197 {
  align-items: flex-start;
  background-color: var(--neutral-00);
  border: 0.72px solid;
  border-color: var(--primary-01);
  border-radius: 2.87px;
  display: flex;
  gap: 3.59px;
  height: 14px;
  left: 4px;
  padding: 2.87px 3.59px;
  position: absolute;
  top: 45px;
  width: 77px;
}

.prosprr .text-wrapper-105 {
  color: var(--neutral-01);
  font-family: "Lexend Deca", Helvetica;
  font-size: 6.1px;
  font-weight: 400;
  letter-spacing: -0.07px;
  line-height: 8.6px;
  margin-bottom: -0.38px;
  margin-top: -0.72px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.prosprr .frame-198 {
  align-items: flex-start;
  background-color: var(--neutral-00);
  border: 0.72px solid;
  border-color: var(--primary-01);
  border-radius: 2.87px;
  display: flex;
  gap: 3.59px;
  height: 14px;
  left: 4px;
  padding: 2.87px 3.59px;
  position: absolute;
  top: 20px;
  width: 77px;
}

.prosprr .leaf-5 {
  height: 68px;
  left: -504px;
  position: absolute;
  top: -7924px;
  width: 101px;
}

.prosprr .istock-2 {
  height: 230px;
  left: -757px;
  position: absolute;
  top: -8096px;
  width: 214px;
}

.prosprr .get-started-6 {
  flex: 0 0 auto;
  margin-left: -2815.14px;
  margin-top: -7990.28px;
  position: relative;
  transform: rotate(-15deg);
}

.prosprr .group-24 {
  height: 290.86px;
  position: relative;
  width: 276.64px;
}

.prosprr .image-22 {
  background-image: url(../../../static/img/vector.png);
  background-size: 100% 100%;
  height: 262px;
  left: -645px;
  position: absolute;
  top: -8580px;
  width: 277px;
}

.prosprr .ETH-2 {
  -webkit-backdrop-filter: blur(6.21px) brightness(100%);
  backdrop-filter: blur(6.21px) brightness(100%);
  background-color: #f7fef8ad;
  border: 1.32px solid;
  border-color: transparent;
  border-image: linear-gradient(to bottom, rgb(247.56, 255, 253.76), rgb(120, 204, 122)) 1;
  border-radius: 6.08px;
  box-shadow: -1.32px 4.39px 7.03px #0e56491f;
  height: 101px;
  left: 17px;
  overflow: hidden;
  position: absolute;
  top: 0;
  width: 84px;
}

.prosprr .group-25 {
  height: 42px;
  left: -722px;
  position: absolute;
  top: -8752px;
  width: 85px;
}

.prosprr .frame-199 {
  align-items: flex-start;
  display: inline-flex;
  flex-direction: column;
  gap: 11.95px;
  left: 0;
  padding: 5.32px;
  position: absolute;
  top: 2px;
}

.prosprr .frame-200 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 33.52px;
  position: relative;
}

.prosprr .frame-201 {
  align-items: center;
  background-color: #fffdfe;
  border-radius: 4.26px;
  box-shadow: 0px 1.1px 3.31px #00000026;
  display: flex;
  gap: 2.66px;
  height: 16.02px;
  justify-content: center;
  padding: 2.66px;
  position: relative;
  width: 16.6px;
}

.prosprr .ethereum-logo-2 {
  height: 13.45px;
  margin-left: -736.84px;
  margin-top: -8814.74px;
  position: relative;
  width: 10.02px;
}

.prosprr .rise-3 {
  align-items: center;
  background-color: #00000033;
  border-radius: 1.6px;
  display: flex;
  gap: 1.47px;
  justify-content: center;
  padding: 0.8px 1.18px;
  position: relative;
  width: 23.59px;
}

.prosprr .group-26 {
  height: 3.2px;
  margin-left: -783.29px;
  margin-top: -8829.06px;
  position: relative;
  width: 3.6px;
}

.prosprr .text-wrapper-106 {
  color: var(--accent-01);
  font-family: "Lexend Deca", Helvetica;
  font-size: 5.6px;
  font-weight: 300;
  letter-spacing: 0.03px;
  line-height: normal;
  margin-top: -0.4px;
  position: relative;
  width: 11.21px;
}

.prosprr .text-wrapper-107 {
  color: var(--primary-02);
  font-family: "Lexend Deca", Helvetica;
  font-size: 3.3px;
  font-weight: 400;
  letter-spacing: -0.04px;
  line-height: 4.6px;
  margin-top: -0.33px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.prosprr .text-wrapper-108 {
  color: var(--primary-02);
  font-family: "Lexend Deca", Helvetica;
  font-size: 11.9px;
  font-weight: 300;
  letter-spacing: -0.15px;
  line-height: 16.7px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.prosprr .get-started-7 {
  flex: 0 0 auto;
  margin-bottom: -14.1px;
  position: relative;
  transform: rotate(-15deg);
}

.prosprr .group-27 {
  height: 259.88px;
  position: relative;
  width: 275.73px;
}

.prosprr .overlap-29 {
  background-image: url(../../../static/img/image-1.png);
  background-size: 100% 100%;
  height: 322px;
  left: -29px;
  position: relative;
  top: -31px;
  width: 334px;
}

.prosprr .check-2 {
  height: 57px;
  left: 239px;
  position: absolute;
  top: 134px;
  width: 55px;
}

.prosprr .frame-202 {
  -webkit-backdrop-filter: blur(6.15px) brightness(100%);
  align-items: flex-start;
  backdrop-filter: blur(6.15px) brightness(100%);
  background-color: #f7fef8b8;
  border: 1.18px solid;
  border-color: transparent;
  border-image: linear-gradient(to bottom, rgb(247.56, 255, 253.76), rgb(120, 204, 122)) 1;
  border-radius: 6.74px;
  box-shadow: -0.42px 0.84px 15.17px #4545451f;
  display: flex;
  flex-direction: column;
  gap: 3.37px;
  height: 52px;
  left: 32px;
  padding: 6.74px;
  position: absolute;
  top: 42px;
  width: 108px;
}

.prosprr .frame-203 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 6.74px;
  position: relative;
}

.prosprr .frame-204 {
  align-items: center;
  display: flex;
  flex: 0 0 auto;
  gap: 26.97px;
  position: relative;
  width: 94.82px;
}

.prosprr .group-28 {
  height: 11.03px;
  position: relative;
  width: 37.5px;
}

.prosprr .overlap-30 {
  height: 11px;
  position: relative;
  width: 37px;
}

.prosprr .group-29 {
  background-color: #f3b82f;
  border-radius: 2.77px;
  height: 11px;
  left: 0;
  position: absolute;
  top: 0;
  width: 11px;
}

.prosprr .vector-20 {
  height: 7px;
  left: 2px;
  position: absolute;
  top: 2px;
  width: 7px;
}

.prosprr .group-30 {
  background-color: #000001;
  border-radius: 2.77px;
  height: 11px;
  left: 9px;
  position: absolute;
  top: 0;
  width: 11px;
}

.prosprr .vector-21 {
  height: 7px;
  left: 1px;
  position: absolute;
  top: 2px;
  width: 8px;
}

.prosprr .group-31 {
  height: 11px;
  left: 18px;
  position: absolute;
  top: 0;
  width: 11px;
}

.prosprr .group-32 {
  height: 11px;
}

.prosprr .overlap-group-13 {
  height: 13px;
  left: -1px;
  position: relative;
  top: -1px;
  width: 13px;
}

.prosprr .rectangle-10 {
  background-color: #162852;
  border-radius: 2.77px;
  height: 11px;
  left: 1px;
  position: absolute;
  top: 1px;
  width: 11px;
}

.prosprr .PNG-vector {
  height: 13px;
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 0;
  width: 13px;
}

.prosprr .group-33 {
  background-color: #e60077;
  border-radius: 2.77px;
  height: 11px;
  left: 26px;
  position: absolute;
  top: 0;
  width: 11px;
}

.prosprr .CTA-2 {
  align-items: center;
  background-color: #388573;
  border-radius: 1.1px;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 1.69px;
  height: 10.12px;
  justify-content: center;
  padding: 2.21px;
  position: relative;
}

.prosprr .text-wrapper-109 {
  color: var(--neutral-00);
  font-family: "Lexend Deca", Helvetica;
  font-size: 3.3px;
  font-weight: 700;
  letter-spacing: -0.04px;
  line-height: 4.6px;
  margin-top: -0.05px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

.prosprr .frame-205 {
  height: 8.1px;
  margin-bottom: -2.07px;
  margin-left: -0.83px;
  margin-right: -0.34px;
  position: relative;
  width: 8.1px;
}

.prosprr .text-wrapper-110 {
  color: var(--neutral-05);
  font-family: "Lexend", Helvetica;
  font-size: 4.9px;
  font-weight: 400;
  letter-spacing: 0.02px;
  line-height: normal;
  position: relative;
  width: fit-content;
}

.prosprr .frame-206 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 10.12px;
  position: relative;
}

.prosprr .frame-207 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 1.69px;
  justify-content: center;
  position: relative;
}

.prosprr .text-wrapper-111 {
  color: var(--neutral-03);
  font-family: "Lexend Deca", Helvetica;
  font-size: 3.5px;
  font-weight: 400;
  letter-spacing: 0.02px;
  line-height: normal;
  margin-top: -0.42px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.prosprr .text-wrapper-112 {
  color: #263f39;
  font-family: "Lexend Deca", Helvetica;
  font-size: 4.2px;
  font-weight: 500;
  letter-spacing: 0.02px;
  line-height: normal;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.prosprr .rise-4 {
  align-items: center;
  background-color: #00000033;
  border-radius: 0.92px;
  display: flex;
  flex: 0 0 auto;
  gap: 0.84px;
  justify-content: center;
  padding: 0.46px 0.67px;
  position: relative;
  width: 12.6px;
}

.prosprr .group-34 {
  height: 2.3px;
  margin-top: -0.09px;
  position: relative;
  width: 2.47px;
}

.prosprr .text-wrapper-113 {
  color: var(--accent-01);
  font-family: "Lexend Deca", Helvetica;
  font-size: 3.2px;
  font-weight: 300;
  letter-spacing: 0.02px;
  line-height: normal;
  margin-top: -0.23px;
  position: relative;
  width: 6.41px;
}

.prosprr .frame-208 {
  height: 524.55px;
  position: relative;
  width: 368.1px;
}

.prosprr .overlap-31 {
  height: 617px;
  left: -96px;
  position: relative;
  width: 561px;
}

.prosprr .frame-209 {
  height: 525px;
  left: 96px;
  overflow: hidden;
  position: absolute;
  top: 0;
  width: 368px;
}

.prosprr .overlap-group-14 {
  height: 709px;
  left: -131px;
  position: relative;
  top: -92px;
  width: 630px;
}

.prosprr .rectangle-11 {
  height: 602px;
  left: 70px;
  position: absolute;
  top: 53px;
  transform: rotate(-15deg);
  width: 491px;
}

.prosprr .frame-210 {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 8.18px;
  left: 147px;
  position: absolute;
  top: 130px;
  width: 335px;
}

.prosprr .headline-22 {
  color: var(--neutral-00);
  font-family: "Lexend Deca", Helvetica;
  font-size: 20.5px;
  font-weight: 600;
  letter-spacing: -0.25px;
  line-height: 24.5px;
  margin-top: -1.02px;
  position: relative;
  text-align: center;
  width: 312.89px;
}

.prosprr .investment-on-2 {
  height: 468px;
  left: 53px;
  position: absolute;
  top: 98px;
  transform: rotate(-15deg);
  width: 455px;
}

.prosprr .frame-211 {
  align-items: center;
  display: inline-flex;
  gap: 98.16px;
  left: 16px;
  position: absolute;
  top: 9px;
}

.prosprr .navigation-menu-px-instance {
  left: -2937px !important;
  top: -7100px !important;
  transform: rotate(-15deg) !important;
}

.prosprr .vector-22 {
  height: 22.5px;
  margin-left: -2905.68px;
  margin-top: -7121.61px;
  position: relative;
  transform: rotate(-15deg);
  width: 78.52px;
}

.prosprr .download-prosppr-2 {
  flex: 0 0 auto;
  margin-left: -2734.37px;
  margin-top: -7168.79px;
  position: relative;
  transform: rotate(-15deg);
}

.prosprr .frame-212 {
  align-items: center;
  display: inline-flex;
  flex-direction: column;
  gap: 32.72px;
  left: 42px;
  position: absolute;
  top: 3143px;
}

.prosprr .android-store-3 {
  align-self: stretch;
  height: 56.04px;
  margin-left: -2.29px;
  margin-right: -2.29px;
  margin-top: -12.53px;
  position: relative;
  transform: rotate(-15deg);
  width: 100%;
}

.prosprr .apple-store-3 {
  height: 53.73px;
  margin-bottom: -8.98px;
  margin-left: -2.93px;
  margin-right: -2px;
  position: relative;
  transform: rotate(-15deg);
  width: 105.8px;
}

.prosprr .image-23 {
  height: 90.45px;
  margin-left: -2937.87px;
  margin-top: -10353.34px;
  position: relative;
  transform: rotate(-15deg);
  width: 90.45px;
}

.prosprr .learning-blog-2 {
  background-color: #ffffff;
  border: 13.7px solid;
  border-color: #ffffff52;
  border-radius: 16px;
  height: 5228px;
  left: 2308px;
  overflow: hidden;
  position: absolute;
  top: -38px;
  transform: rotate(15deg);
  width: 396px;
}

.prosprr .overlap-32 {
  height: 3608px;
  left: -3px;
  position: absolute;
  top: 1592px;
  width: 373px;
}

.prosprr .frame-213 {
  align-items: center;
  background-color: #101728;
  display: inline-flex;
  flex-direction: column;
  gap: 16.36px;
  left: 3px;
  padding: 24.54px 16.36px;
  position: absolute;
  top: 3189px;
}

.prosprr .prosppr-logo-4 {
  height: 32.72px;
  margin-left: -3428.09px;
  margin-top: -11336.51px;
  position: relative;
  transform: rotate(-15deg);
  width: 106.34px;
}

.prosprr .image-24 {
  height: 44.99px;
  margin-left: -3426.99px;
  margin-top: -11429.96px;
  position: relative;
  transform: rotate(-15deg);
  width: 134.97px;
}

.prosprr .image-25 {
  height: 44.99px;
  margin-left: -3284.76px;
  margin-top: -11468.06px;
  position: relative;
  transform: rotate(-15deg);
  width: 134.97px;
}

.prosprr .divider-8 {
  height: 1px;
  margin-left: -3370.49px;
  margin-top: -11120.29px;
  position: relative;
  transform: rotate(-15deg);
  width: 335.38px;
}

.prosprr .social-buttons-4 {
  flex: 0 0 auto;
  margin-left: -6507.99px;
  margin-top: -11663.86px;
  position: relative;
  transform: rotate(-15deg);
}

.prosprr .divider-9 {
  height: 1px;
  margin-left: -3355.67px;
  margin-top: -11064.98px;
  position: relative;
  transform: rotate(-15deg);
  width: 335.38px;
}

.prosprr .frame-214 {
  align-items: flex-start;
  background-color: #ffffff;
  border-bottom-style: solid;
  border-bottom-width: 5.11px;
  border-color: #d14e4e;
  box-shadow: 0px 7.16px 16.36px #d14e4e40;
  display: flex;
  flex-direction: column;
  gap: 24.54px;
  left: 0;
  padding: 24.54px 16.36px;
  position: absolute;
  top: 2390px;
  width: 373px;
}

.prosprr .frame-215 {
  align-items: center;
  display: flex;
  flex: 0 0 auto;
  gap: 8.18px;
  position: relative;
  width: 259.72px;
}

.prosprr .text-wrapper-114 {
  color: var(--neutral-05);
  font-family: "Lexend Deca", Helvetica;
  font-size: 28.6px;
  font-weight: 600;
  letter-spacing: -0.35px;
  line-height: 34.4px;
  margin-top: -1.02px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.prosprr .rectangle-12 {
  height: 24.54px;
  margin-left: -3287.26px;
  margin-top: -10562px;
  position: relative;
  transform: rotate(-15deg);
  width: 24.54px;
}

.prosprr .frame-216 {
  align-items: flex-start;
  align-self: stretch;
  background-color: #ffffff;
  box-shadow: 0.92px 6.44px 22.07px #00000012;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  overflow: hidden;
  position: relative;
  width: 100%;
}

.prosprr .frame-217 {
  align-self: stretch;
  height: 228.02px;
  overflow: hidden;
  position: relative;
  width: 100%;
}

.prosprr .overlap-group-15 {
  height: 518px;
  left: -3448px;
  position: relative;
  top: -10747px;
  width: 653px;
}

.prosprr .image-26 {
  height: 382px;
  left: 40px;
  object-fit: cover;
  position: absolute;
  top: 68px;
  transform: rotate(-15deg);
  width: 574px;
}

.prosprr .image-27 {
  height: 232px;
  left: 28px;
  object-fit: cover;
  position: absolute;
  top: 113px;
  transform: rotate(-15deg);
  width: 348px;
}

.prosprr .image-28 {
  height: 254px;
  left: 29px;
  object-fit: cover;
  position: absolute;
  top: 113px;
  transform: rotate(-15deg);
  width: 379px;
}

.prosprr .image-29 {
  height: 243px;
  left: 28px;
  object-fit: cover;
  position: absolute;
  top: 106px;
  transform: rotate(-15deg);
  width: 365px;
}

.prosprr .frame-218 {
  align-items: flex-start;
  align-self: stretch;
  border-radius: 11.03px;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 16.36px;
  padding: 24.54px 16.36px;
  position: relative;
  width: 100%;
}

.prosprr .text-wrapper-115 {
  align-self: stretch;
  color: var(--neutral-04);
  font-family: "Lexend Deca", Helvetica;
  font-size: 16.4px;
  font-weight: 500;
  letter-spacing: -0.2px;
  line-height: 19.6px;
  margin-top: -0.92px;
  position: relative;
}

.prosprr .frame-219 {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  gap: 8.18px;
  position: relative;
  width: 100%;
}

.prosprr .text-wrapper-116 {
  color: var(--neutral-04);
  font-family: "Lexend Deca", Helvetica;
  font-size: 10.2px;
  font-weight: 400;
  letter-spacing: -0.07px;
  line-height: 14.3px;
  margin-top: -0.92px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.prosprr .ellipse-30 {
  background-color: var(--neutral-05);
  border-radius: 1.84px;
  height: 3.68px;
  position: relative;
  width: 3.68px;
}

.prosprr .text-wrapper-117 {
  color: var(--neutral-02);
  font-family: "Lexend Deca", Helvetica;
  font-size: 10.2px;
  font-weight: 400;
  letter-spacing: -0.07px;
  line-height: 14.3px;
  margin-top: -0.92px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.prosprr .frame-220 {
  align-items: center;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 24.54px;
  position: relative;
  width: 335.38px;
}

.prosprr .frame-221 {
  align-items: flex-start;
  align-self: stretch;
  background-color: #ffffff;
  border-bottom-style: solid;
  border-bottom-width: 2.25px;
  border-color: var(--primary-05);
  display: flex;
  flex: 0 0 auto;
  gap: 24.54px;
  padding: 16.36px;
  position: relative;
  width: 100%;
}

.prosprr .frame-222 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 16.36px;
  position: relative;
}

.prosprr .frame-223 {
  height: 92.39px;
  overflow: hidden;
  position: relative;
  width: 98.16px;
}

.prosprr .image-30 {
  height: 162px;
  left: -3483px;
  object-fit: cover;
  position: absolute;
  top: -11035px;
  transform: rotate(-15deg);
  width: 243px;
}

.prosprr .text-wrapper-118 {
  color: var(--neutral-04);
  font-family: "Lexend Deca", Helvetica;
  font-size: 16.4px;
  font-weight: 500;
  letter-spacing: -0.2px;
  line-height: 19.6px;
  margin-top: -1.02px;
  position: relative;
  width: 156.44px;
}

.prosprr .frame-224 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 4.09px;
  position: relative;
}

.prosprr .text-wrapper-119 {
  color: var(--neutral-04);
  font-family: "Lexend Deca", Helvetica;
  font-size: 10.2px;
  font-weight: 400;
  letter-spacing: -0.07px;
  line-height: 14.3px;
  margin-top: -1.02px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.prosprr .text-wrapper-120 {
  color: var(--neutral-02);
  font-family: "Lexend Deca", Helvetica;
  font-size: 10.2px;
  font-weight: 400;
  letter-spacing: -0.07px;
  line-height: 14.3px;
  margin-top: -1.02px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.prosprr .image-31 {
  height: 162px;
  left: -3483px;
  object-fit: cover;
  position: absolute;
  top: -11160px;
  transform: rotate(-15deg);
  width: 243px;
}

.prosprr .more-blogs-2 {
  flex: 0 0 auto;
  margin-left: -3254.84px;
  margin-top: -10754.58px;
  position: relative;
  transform: rotate(-15deg);
}

.prosprr .frame-225 {
  align-items: flex-start;
  background-color: #ffffff;
  border-bottom-style: solid;
  border-bottom-width: 5.11px;
  border-color: #2a56c5;
  box-shadow: 0px 7.16px 16.36px #2b56c640;
  display: flex;
  flex-direction: column;
  gap: 24.54px;
  left: 0;
  padding: 24.54px 16.36px;
  position: absolute;
  top: 1593px;
  width: 373px;
}

.prosprr .rectangle-13 {
  height: 22.25px;
  position: relative;
  transform: rotate(-15deg);
  width: 30.06px;
}

.prosprr .overlap-group-16 {
  height: 396px;
  left: -57px;
  position: relative;
  top: -84px;
  width: 449px;
}

.prosprr .image-32 {
  height: 307px;
  left: 33px;
  object-fit: cover;
  position: absolute;
  top: 44px;
  transform: rotate(-15deg);
  width: 383px;
}

.prosprr .image-33 {
  height: 5px;
  left: -24px;
  object-fit: cover;
  position: absolute;
  top: -9px;
  transform: rotate(-15deg);
  width: 119px;
}

.prosprr .image-34 {
  height: 162px;
  left: -3483px;
  object-fit: cover;
  position: absolute;
  top: -10363px;
  transform: rotate(-15deg);
  width: 243px;
}

.prosprr .more-blogs-3 {
  flex: 0 0 auto;
  margin-left: -3254.84px;
  margin-top: -9958.05px;
  position: relative;
  transform: rotate(-15deg);
}

.prosprr .frame-226 {
  align-items: flex-start;
  background-color: #ffffff;
  border-bottom-style: solid;
  border-bottom-width: 5.11px;
  border-color: #408fd7;
  box-shadow: 0px 7.16px 16.36px #418fd840;
  display: flex;
  flex-direction: column;
  gap: 24.54px;
  left: 0;
  padding: 24.54px 16.36px;
  position: absolute;
  top: 797px;
  width: 373px;
}

.prosprr .image-35 {
  height: 115px;
  left: -10px;
  object-fit: cover;
  position: absolute;
  top: -11px;
  transform: rotate(-15deg);
  width: 119px;
}

.prosprr .more-blogs-4 {
  flex: 0 0 auto;
  margin-bottom: -15.22px;
  position: relative;
  transform: rotate(-15deg);
}

.prosprr .frame-227 {
  align-items: flex-start;
  background-color: #ffffff;
  border-bottom-style: solid;
  border-bottom-width: 5.11px;
  border-color: var(--primary-02);
  box-shadow: 0px 7.16px 16.36px #c3e4cc40;
  display: flex;
  flex-direction: column;
  gap: 24.54px;
  left: 0;
  padding: 24.54px 16.36px;
  position: absolute;
  top: 0;
  width: 373px;
}

.prosprr .rectangle-14 {
  height: 24.54px;
  margin-left: -3208.06px;
  margin-top: -8193.62px;
  position: relative;
  transform: rotate(-15deg);
  width: 24.54px;
}

.prosprr .image-36 {
  height: 382px;
  left: -3408px;
  object-fit: cover;
  position: absolute;
  top: -8290px;
  transform: rotate(-15deg);
  width: 574px;
}

.prosprr .hero-section-BG-3 {
  height: 1594px;
  left: -3214px;
  position: absolute;
  top: -6592px;
  transform: rotate(-15deg);
  width: 368px;
}

.prosprr .overlap-33 {
  height: 1563px;
  left: 0;
  position: absolute;
  top: 0;
  width: 531px;
}

.prosprr .ellipse-31 {
  background: radial-gradient(50% 50% at 50% 50%, rgba(246.5, 231.5, 96.55, 0.27) 0%, rgba(217, 217, 217, 0) 100%);
  border-radius: 189.16px;
  height: 378px;
  left: 152px;
  position: absolute;
  top: 874px;
  width: 378px;
}

.prosprr .component-3 {
  height: 428px;
  left: 0;
  overflow: hidden;
  position: absolute;
  top: 57px;
  width: 368px;
}

.prosprr .overlap-34 {
  height: 378px;
  left: -147px;
  position: relative;
  top: 39px;
  width: 504px;
}

.prosprr .ellipse-32 {
  background: radial-gradient(50% 50% at 50% 50%, rgba(97.15, 224.19, 147.96, 0.42) 0%, rgba(217, 217, 217, 0) 88.55%);
  border-radius: 171.58px;
  filter: blur(2.18px);
  height: 343px;
  left: 0;
  position: absolute;
  top: 35px;
  width: 343px;
}

.prosprr .frame-228 {
  align-items: center;
  display: inline-flex;
  flex-direction: column;
  gap: 12.27px;
  justify-content: center;
  left: 157px;
  padding: 6.14px 16.36px;
  position: absolute;
  top: 0;
}

.prosprr .text-wrapper-121 {
  color: var(--neutral-04);
  font-family: "Lexend Deca", Helvetica;
  font-size: 28.6px;
  font-weight: 600;
  letter-spacing: -0.86px;
  line-height: 32.7px;
  margin-top: -1.02px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

.prosprr .frame-229 {
  align-items: center;
  display: inline-flex;
  gap: 98.16px;
  left: 0;
  padding: 12.27px 16.36px;
  position: absolute;
  top: 0;
}

.prosprr .navigation-menu-24px-instance {
  left: -3425px !important;
  top: -6533px !important;
  transform: rotate(-15deg) !important;
}

.prosprr .vector-23 {
  height: 22.5px;
  margin-left: -3393.12px;
  margin-top: -6554.21px;
  position: relative;
  transform: rotate(-15deg);
  width: 78.52px;
}

.prosprr .download-prosppr-3 {
  flex: 0 0 auto;
  margin-left: -3202.82px;
  margin-top: -6593.77px;
  position: relative;
  transform: rotate(-15deg);
}

.prosprr .frame-230 {
  align-items: flex-start;
  display: inline-flex;
  flex-direction: column;
  gap: 32.72px;
  left: 16px;
  position: absolute;
  top: 239px;
}

.prosprr .how-compounding-8 {
  align-items: center;
  background-color: #ffffff;
  border-radius: 16.36px;
  box-shadow: 3.07px 7.16px 12.27px #0e56490f;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 16.36px;
  position: relative;
  width: 335.38px;
}

.prosprr .frame-231 {
  align-items: flex-start;
  align-self: stretch;
  border-radius: 12.27px;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  overflow: hidden;
  position: relative;
  width: 100%;
}

.prosprr .image-37 {
  align-self: stretch;
  height: 200.41px;
  margin-left: -3410.29px;
  margin-top: -6803.51px;
  object-fit: cover;
  position: relative;
  transform: rotate(-15deg);
  width: 100%;
}

.prosprr .frame-232 {
  align-items: flex-start;
  align-self: stretch;
  border-radius: 12.27px;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 8.18px;
  padding: 24.54px 12.27px;
  position: relative;
  width: 100%;
}

.prosprr .text-wrapper-122 {
  color: var(--primary-02);
  font-family: "Lexend Deca", Helvetica;
  font-size: 10.2px;
  font-weight: 400;
  letter-spacing: 0.33px;
  line-height: 14.3px;
  margin-right: -396.73px;
  margin-top: -1.02px;
  position: relative;
  width: 707.58px;
}

.prosprr .frame-233 {
  align-items: flex-start;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 8.18px;
  position: relative;
  width: 310.84px;
}

.prosprr .frame-234 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 8.18px;
  position: relative;
  width: 100%;
}

.prosprr .text-wrapper-123 {
  align-self: stretch;
  color: var(--neutral-04);
  font-family: "Lexend Deca", Helvetica;
  font-size: 16.4px;
  font-weight: 500;
  letter-spacing: -0.2px;
  line-height: 19.6px;
  margin-top: -1.02px;
  position: relative;
}

.prosprr .text-wrapper-124 {
  align-self: stretch;
  color: var(--neutral-01);
  font-family: "Lexend Deca", Helvetica;
  font-size: 12.3px;
  font-weight: 400;
  letter-spacing: -0.15px;
  line-height: 17.2px;
  position: relative;
}

.prosprr .text-wrapper-125 {
  color: var(--primary-02);
  font-family: "Lexend Deca", Helvetica;
  font-size: 14.3px;
  font-weight: 500;
  letter-spacing: -0.17px;
  line-height: 20px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.prosprr .ellipse-33 {
  background-color: var(--neutral-05);
  border-radius: 2.05px;
  height: 4.09px;
  position: relative;
  width: 4.09px;
}

.prosprr .popular-2 {
  align-items: flex-start;
  background-color: #ffffff;
  border-radius: 16.36px;
  box-shadow: 3.07px 7.16px 12.27px #0e56490f;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 24.54px;
  padding: 16.36px 12.27px;
  position: relative;
  width: 335.38px;
}

.prosprr .frame-235 {
  align-items: flex-start;
  align-self: stretch;
  border-bottom-style: solid;
  border-bottom-width: 2.25px;
  border-color: var(--primary-05);
  display: flex;
  flex: 0 0 auto;
  padding: 0px 0px 12.27px;
  position: relative;
  width: 100%;
}

.prosprr .text-wrapper-126 {
  color: var(--neutral-05);
  font-family: "Lexend Deca", Helvetica;
  font-size: 20.5px;
  font-weight: 600;
  letter-spacing: -0.25px;
  line-height: 24.5px;
  margin-top: -2.25px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.prosprr .frame-236 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 8.18px;
  padding: 8.18px 0px;
  position: relative;
  width: 100%;
}

.prosprr .text-wrapper-127 {
  align-self: stretch;
  color: var(--primary-02);
  font-family: "Lexend Deca", Helvetica;
  font-size: 10.2px;
  font-weight: 400;
  letter-spacing: 0.33px;
  line-height: 14.3px;
  margin-top: -2.45px;
  position: relative;
}

.prosprr .text-wrapper-128 {
  align-self: stretch;
  color: var(--neutral-05);
  font-family: "Lexend Deca", Helvetica;
  font-size: 14.3px;
  font-weight: 500;
  letter-spacing: -0.17px;
  line-height: 17.2px;
  position: relative;
}

.prosprr .text-wrapper-129 {
  align-self: stretch;
  color: var(--primary-02);
  font-family: "Lexend Deca", Helvetica;
  font-size: 10.2px;
  font-weight: 400;
  letter-spacing: 0.33px;
  line-height: 14.3px;
  margin-top: -2.25px;
  position: relative;
}

.prosprr .text-wrapper-130 {
  align-self: stretch;
  color: var(--primary-02);
  font-family: "Lexend Deca", Helvetica;
  font-size: 10.2px;
  font-weight: 400;
  letter-spacing: 0.33px;
  line-height: 14.3px;
  margin-top: -2.05px;
  position: relative;
}

.prosprr .frame-237 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 24.54px;
  position: relative;
}

.prosprr .frame-238 {
  align-items: flex-start;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 16.36px;
  overflow-x: scroll;
  position: relative;
  width: 335.38px;
}

.prosprr .frame-239 {
  align-items: flex-start;
  align-self: stretch;
  border-bottom-style: solid;
  border-bottom-width: 2.05px;
  border-color: #00766287;
  display: flex;
  flex: 0 0 auto;
  padding: 0px 0px 16.36px;
  position: relative;
  width: 100%;
}

.prosprr .text-wrapper-131 {
  color: var(--neutral-05);
  font-family: "Lexend Deca", Helvetica;
  font-size: 20.5px;
  font-weight: 600;
  letter-spacing: -0.25px;
  line-height: 24.5px;
  margin-top: -2.05px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.prosprr .frame-240 {
  align-items: center;
  background-color: var(--primary-02);
  border-radius: 7.16px;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 10.23px;
  justify-content: center;
  padding: 8.18px 16.36px;
  position: relative;
}

.prosprr .text-wrapper-132 {
  color: var(--accent-01);
  font-family: "Lexend Deca", Helvetica;
  font-size: 12.3px;
  font-weight: 500;
  letter-spacing: -0.15px;
  line-height: 17.2px;
  margin-top: -1.02px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.prosprr .frame-241 {
  align-items: center;
  background-color: #007d681f;
  border-radius: 7.16px;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 10.23px;
  justify-content: center;
  padding: 8.18px 16.36px;
  position: relative;
}

.prosprr .text-wrapper-133 {
  color: var(--primary-02);
  font-family: "Lexend Deca", Helvetica;
  font-size: 12.3px;
  font-weight: 500;
  letter-spacing: -0.15px;
  line-height: 17.2px;
  margin-top: -1.02px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.prosprr .frame-242 {
  align-items: center;
  background-color: #007d681f;
  border-radius: 7.16px;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 10.23px;
  justify-content: center;
  margin-right: -49.31px;
  padding: 8.18px 16.36px;
  position: relative;
}

.prosprr .frame-243 {
  align-items: center;
  background-color: #007d681f;
  border-radius: 7.16px;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 10.23px;
  justify-content: center;
  margin-right: -158.3px;
  padding: 8.18px 16.36px;
  position: relative;
}

.prosprr .frame-244 {
  align-items: center;
  background-color: #007d681f;
  border-radius: 7.16px;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 10.23px;
  justify-content: center;
  margin-right: -252.29px;
  padding: 8.18px 16.36px;
  position: relative;
}

.prosprr .frame-245 {
  align-items: flex-start;
  display: flex;
  flex: 0 0 auto;
  gap: 16.36px;
  overflow: hidden;
  overflow-x: scroll;
  position: relative;
  width: 335.38px;
}

.prosprr .frame-246 {
  align-items: flex-start;
  background-color: #ffffff;
  border-radius: 11.03px;
  box-shadow: 0.92px 6.44px 22.07px #00000012;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  position: relative;
  width: 264.83px;
}

.prosprr .frame-247 {
  align-self: stretch;
  height: 163.6px;
  overflow: hidden;
  position: relative;
  width: 100%;
}

.prosprr .image-38 {
  height: 654px;
  left: -187px;
  object-fit: cover;
  position: absolute;
  top: -196px;
  transform: rotate(-15deg);
  width: 771px;
}

.prosprr .frame-248 {
  align-items: flex-start;
  align-self: stretch;
  border-radius: 11.03px;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 49.08px;
  padding: 16.36px 12.27px;
  position: relative;
  width: 100%;
}

.prosprr .text-wrapper-134 {
  color: var(--primary-02);
  font-family: "Lexend Deca", Helvetica;
  font-size: 10.2px;
  font-weight: 400;
  letter-spacing: 0.33px;
  line-height: 14.3px;
  margin-top: -0.92px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.prosprr .text-wrapper-135 {
  align-self: stretch;
  color: var(--neutral-01);
  font-family: "Lexend Deca", Helvetica;
  font-size: 12.3px;
  font-weight: 400;
  letter-spacing: -0.15px;
  line-height: 17.2px;
  margin-top: -0.92px;
  position: relative;
}

.prosprr .read-more-10 {
  align-self: stretch;
  flex: 0 0 auto;
  margin-bottom: -30.75px;
  position: relative;
  transform: rotate(-15deg);
  width: 100%;
}

.prosprr .frame-249 {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  gap: 7.36px;
  position: relative;
  width: 100%;
}

.prosprr .overlap-group-17 {
  height: 831px;
  left: -259px;
  position: relative;
  top: -285px;
  width: 914px;
}

.prosprr .image-39 {
  height: 654px;
  left: 71px;
  object-fit: cover;
  position: absolute;
  top: 89px;
  transform: rotate(-15deg);
  width: 771px;
}

.prosprr .image-40 {
  height: 374px;
  left: 221px;
  object-fit: cover;
  position: absolute;
  top: 233px;
  transform: rotate(-15deg);
  width: 472px;
}

.prosprr .image-41 {
  height: 456px;
  left: 61px;
  object-fit: cover;
  position: absolute;
  top: 208px;
  transform: rotate(-15deg);
  width: 575px;
}

.prosprr .menu {
  background-color: var(--primary-04);
  border: 13.7px solid;
  border-color: #ffffff52;
  border-radius: 16px;
  height: 764px;
  left: 1352px;
  overflow: hidden;
  position: absolute;
  top: 2088px;
  transform: rotate(15deg);
  width: 396px;
}

.prosprr .frame-250 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 8.18px;
  left: 0;
  padding: 16.36px 16.36px 24.54px;
  position: absolute;
  top: 58px;
  width: 368px;
}

.prosprr .frame-251 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  padding: 12.27px 0px;
  position: relative;
  width: 100%;
}

.prosprr .text-wrapper-136 {
  color: var(--neutral-00);
  font-family: "Lexend Deca", Helvetica;
  font-size: 16.4px;
  font-weight: 500;
  letter-spacing: -0.2px;
  line-height: 22.9px;
  margin-top: -1.02px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.prosprr .frame-252 {
  align-items: center;
  display: flex;
  justify-content: space-between;
  left: 0;
  padding: 12.27px 16.36px;
  position: absolute;
  top: 0;
  width: 368px;
}

.prosprr .frame-253 {
  height: 51.35px;
  margin-bottom: -12.38px;
  margin-right: -1.75px;
  margin-top: -12.38px;
  position: relative;
  transform: rotate(-15deg);
  width: 102.68px;
}

.prosprr .download-prosppr-4 {
  flex: 0 0 auto;
  margin-bottom: -16.33px;
  margin-right: -2.09px;
  margin-top: -16.32px;
  position: relative;
  transform: rotate(-15deg);
}
