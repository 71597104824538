:root {
  --accent-01: rgba(233, 236, 240, 1);
  --accent-02: rgba(221, 233, 231, 1);
  --accent-03: rgba(201, 249, 223, 1);
  --core-colorswhite: rgba(255, 255, 255, 1);
  --neutral-00: rgba(255, 255, 255, 1);
  --neutral-01: rgba(128, 128, 128, 1);
  --neutral-02: rgba(106, 113, 130, 1);
  --neutral-03: rgba(72, 80, 88, 1);
  --neutral-04: rgba(16, 23, 40, 1);
  --neutral-05: rgba(23, 23, 26, 1);
  --primary-01: rgba(120, 204, 122, 1);
  --primary-02: rgba(0, 125, 104, 1);
  --primary-03: rgba(0, 118, 98, 1);
  --primary-04: rgba(120, 204, 122, 1);
  --primary-05: rgba(0, 118, 98, 0.22);
}
